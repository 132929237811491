import { useState } from 'react'
import { Alert, IconButton, Snackbar, Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

export interface CopyToClipboardButtonParams {
    getText: () => string
    disabled?: boolean
}

const CopyToClipboardButton = ({ getText, disabled }: CopyToClipboardButtonParams) => {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(true)
        window.navigator.clipboard.writeText(getText()).then(() => undefined)
    }

    return (
        <>
            <IconButton onClick={handleClick} color='primary' disabled={disabled}>
                <ContentCopyIcon />
            </IconButton>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                open={open}
            >
                <Alert
                    severity='success'
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid rgb(200, 220, 200)',
                    }}
                >
                    <Typography variant='body1'>Copied to clipboard</Typography>
                </Alert>
            </Snackbar>
        </>
    )
}

export default CopyToClipboardButton
