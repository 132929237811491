import JoinLeftIcon from '@mui/icons-material/JoinLeft'
import { Button, Tooltip } from '@mui/material'
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    ToolbarPropsOverrides,
} from '@mui/x-data-grid-premium'
import { useTranslation } from 'react-i18next'
import AppsIcon from '@mui/icons-material/Apps'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import { Waves } from '@mui/icons-material'

function OverlapButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Show gene overlap between the selected signatures. Max 100 signatures.'} arrow>
            <span>
                <Button startIcon={<JoinLeftIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('viewOverlap')}
                </Button>
            </span>
        </Tooltip>
    )
}

function SimilarityMatrixButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Show the similarity matrix for the selected signatures. Max 100 signatures.'} arrow>
            <span>
                <Button startIcon={<AppsIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('similarityMatrix')}
                </Button>
            </span>
        </Tooltip>
    )
}

function GeneSignatureModuleButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Visualize gene modules across the selected signatures. Max 100 signatures.'} arrow>
            <span>
                <Button startIcon={<ViewModuleIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('geneSignatureModule')}
                </Button>
            </span>
        </Tooltip>
    )
}

function SimilarGeneSignatureButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Visualize the most similar cases for the selected signature. Exactly 1 signature.'} arrow>
            <span>
                <Button startIcon={<LeaderboardIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('similarCases')}
                </Button>
            </span>
        </Tooltip>
    )
}

function CoPerturbedGenesButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Determine the most frequently co-perturbed genes with your target gene.'} arrow>
            <span>
                <Button startIcon={<Waves />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('coPerturbedGenes')}
                </Button>
            </span>
        </Tooltip>
    )
}

declare module '@mui/x-data-grid-premium' {
    interface ToolbarPropsOverrides {
        overlapCallback: () => void
        disabledOverlap: boolean
        similarityCallback: () => void
        disableSimilarity: boolean
        geneModulesCallback: () => void
        disableGeneModules: boolean
        similarGeneSignaturesCallback: () => void
        disableSimilarGeneSignatures: boolean
        coPerturbedGenesCallback: () => void
        disabledCoPerturbedGenes: boolean
    }
}

function SignatureTableGridToolbar({
    overlapCallback,
    disabledOverlap,
    similarityCallback,
    disableSimilarity,
    geneModulesCallback,
    disableGeneModules,
    similarGeneSignaturesCallback,
    disableSimilarGeneSignatures,
    coPerturbedGenesCallback,
    disabledCoPerturbedGenes,
}: ToolbarPropsOverrides) {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton slotProps={{ tooltip: { arrow: true } }} />
            <GridToolbarExport />
            <OverlapButton onClick={overlapCallback} disabled={disabledOverlap} />
            <SimilarityMatrixButton onClick={similarityCallback} disabled={disableSimilarity} />
            <GeneSignatureModuleButton onClick={geneModulesCallback} disabled={disableGeneModules} />
            <SimilarGeneSignatureButton
                onClick={similarGeneSignaturesCallback}
                disabled={disableSimilarGeneSignatures}
            />
            <CoPerturbedGenesButton onClick={coPerturbedGenesCallback} disabled={disabledCoPerturbedGenes} />
        </GridToolbarContainer>
    )
}

export default SignatureTableGridToolbar
