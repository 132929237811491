import {
    Alert,
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    ListSubheader,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../app/hooks'
import '../../extensions/string.ext'
import { useDeliberateDialogClose } from '../../hooks/useDeliberateDialogClose'
import { useSubmitOnEnter } from '../../hooks/useSubmitOnEnter'
import { DialogProps } from '../../model/model'
import { numberInInterval } from '../../utils/misc'
import { useCreateRuntimeMutation, useGetRuntimeOptionsQuery } from './runtimeApiSlice'
import { setRuntime } from './runtimeSlice'

export default function CreateRuntimeDialog(props: DialogProps) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const { data: getRuntimeOptionsResponse } = useGetRuntimeOptionsQuery()
    const [instanceType, setInstanceType] = useState('')
    const [instanceTypeError, setInstanceTypeError] = useState(false)
    const [maxIdleTimeHours, setMaxIdleTimeHours] = useState('')
    const [maxIdleTimeHoursError, setMaxIdleTimeHoursError] = useState(false)
    const cpuRuntimeOptions = getRuntimeOptionsResponse?.filter((ro) => ro.proc == 'CPU')
    const gpuRuntimeOptions = getRuntimeOptionsResponse?.filter((ro) => ro.proc == 'GPU')
    const [createRuntime, { isLoading: isCreating }] = useCreateRuntimeMutation()

    const maxIdleTimeHoursLimit = 12

    const handleChange = (event: SelectChangeEvent) => {
        setInstanceType(event.target.value as string)
    }

    const handleMaxIdleTimeHoursChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setMaxIdleTimeHours(event.target.value)
    }

    const validate = () => {
        resetErrors()
        let valid = true
        if (!instanceType) {
            setInstanceTypeError(true)
            valid = false
        }
        if (!maxIdleTimeHours) {
            setMaxIdleTimeHoursError(true)
            valid = false
        }
        if (!numberInInterval(maxIdleTimeHours, 1, maxIdleTimeHoursLimit)) {
            setMaxIdleTimeHoursError(true)
            valid = false
        }
        return valid
    }

    const resetErrors = () => {
        setInstanceTypeError(false)
        setMaxIdleTimeHoursError(false)
    }

    const submit = async () => {
        if (!validate()) {
            return
        }
        const resp = await createRuntime({
            runtimeOptionId: parseInt(instanceType),
            maxIdleTimeHours: parseInt(maxIdleTimeHours),
        }).unwrap()

        dispatch(
            setRuntime({
                exists: resp.exists,
                proc: resp.proc,
                instanceType: resp.instanceType,
                createdAt: resp.createdAt ? resp.createdAt.parseAndFormatDate() : '',
                maxIdleTimeHours: resp.maxIdleTimeHours,
                connected: false,
            }),
        )
        close()
    }

    useSubmitOnEnter(submit, props.openDialog)

    const close = useDeliberateDialogClose(() => {
        resetErrors()
        setInstanceType('')
        setMaxIdleTimeHours('')
        props.handleCloseDialog()
    })

    return (
        <Dialog
            open={props.openDialog}
            onClose={close}
            aria-labelledby='create-runtime-alert-dialog-title'
            aria-describedby='create-runtime-alert-dialog-description'
        >
            <DialogTitle id='create-runtime-alert-dialog-title'>{t('createRuntimeConfirmTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText id='create-runtime-alert-dialog-description'>
                    <Typography sx={{ mb: 2 }}>{t('createRuntimeConfirmContent')}</Typography>
                    <Alert severity='info'>{t('createRuntimeSizeRecommendation')}</Alert>
                </DialogContentText>
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id='instance-type-select-label'>{t('instanceType')}</InputLabel>
                    <Select
                        labelId='instance-type-select-label'
                        id='instance-type-select'
                        value={instanceType}
                        label={t('instanceType')}
                        onChange={handleChange}
                        error={instanceTypeError}
                        required
                    >
                        <ListSubheader>{t('cpuOnly')}</ListSubheader>
                        {cpuRuntimeOptions?.map((ro) => {
                            return (
                                <MenuItem key={`runtime-option-${ro.id}`} value={ro.id} dense={true}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                        <Typography fontSize='1em'>{ro.instanceType}</Typography>
                                        <Typography fontSize='1em' fontStyle='italic' color='#999'>
                                            {ro.vcpus} vCPU, {ro.memoryGib} GiB
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            )
                        })}
                        <ListSubheader>{t('gpu')}</ListSubheader>
                        {gpuRuntimeOptions?.map((ro) => {
                            return (
                                <MenuItem key={`runtime-option-${ro.id}`} value={ro.id} dense={true}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Typography fontSize='1em'>{ro.instanceType}</Typography>
                                        <Typography fontSize='1em' fontStyle='italic' color='#999'>
                                            {ro.vcpus} vCPU, {ro.memoryGib} GiB, {ro.gpus} GPUs, {ro.gpuMemory} GiB
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <TextField
                    label={'Max Idle Time (hours)'}
                    type={'number'}
                    value={maxIdleTimeHours}
                    onChange={handleMaxIdleTimeHoursChange}
                    helperText={`Input a number between 1 and ${maxIdleTimeHoursLimit}. The instance will be automatically terminated if no commands are being launched within the specified idle time.`}
                    error={maxIdleTimeHoursError}
                    sx={{ mt: 2 }}
                    required
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('close')}
                </Button>
                {!isCreating ? (
                    <Button onClick={submit} autoFocus>
                        {t('create')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
