import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Link, Stack, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { DataFrame, readCSV } from 'danfojs'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { useAppDispatch } from '../../../../app/hooks'
import { useDeliberateDialogClose } from '../../../../hooks/useDeliberateDialogClose'
import { DialogProps } from '../../../../model/model'
import {
    absGreaterThanOrEqualFilterOperator,
    absLowerThanFilterOperator,
    SingleGeneExtractorFactory,
} from '../../../../utils/grid'
import { SlideUpTransition } from '../../../../utils/transitions'
import { useGetDegsDataFrameUrlQuery } from '../../../common-api/geneSignatureApiSlice'
import { receivedAppMessage } from '../../../dashboard/appMessageSlice'
import CreateOrUpdateGeneListDialog from '../../../genelist/CreateOrUpdateGeneListDialog'
import DataFrameGrid, { Render } from './DataFrameGrid'
import GridToolbarWithSaveGenes from './GridToolbarWithSaveGenes'
import LoadingComponentData from './LoadingComponentData'
import VolcanoPlot from './VolcanoPlot'

export interface GeneSignatureQuickViewDialogData {
    geneSignatureId: number
    name: string
    caseValue: string
    controlValue: string
    analysisUri: string | null
    onGeneClick?: (gene: string) => void
}

interface GeneSignatureQuickViewDialogParams extends GeneSignatureQuickViewDialogData, DialogProps {}

export default function GeneSignatureQuickViewDialog({
    geneSignatureId,
    name,
    caseValue,
    controlValue,
    analysisUri,
    openDialog,
    handleCloseDialog,
    onGeneClick,
}: GeneSignatureQuickViewDialogParams) {
    const dispatch = useAppDispatch()
    const { data: resp } = useGetDegsDataFrameUrlQuery(geneSignatureId, { refetchOnMountOrArgChange: true })
    const [degsDataFrame, setDegsDataFrame] = useState<DataFrame | null>(null)
    const [openCreateGeneListDialog, setOpenCreateGeneListDialog] = useState(false)
    const [filteredGenes, setFilteredGenes] = useState<string[]>([])

    const { width = 0, height = 0 } = useWindowSize()
    const close = useDeliberateDialogClose(handleCloseDialog)

    useEffect(() => {
        if (!resp) {
            return
        }
        readCSV(resp.url, { skipEmptyLines: 'greedy' })
            .then((df) => {
                setDegsDataFrame(df)
            })
            .catch(() => {
                dispatch(
                    receivedAppMessage({
                        type: 'error',
                        message: 'Error loading gene signature data.',
                    }),
                )
            })
    }, [resp])

    const geneNameRenderer = (params: GridRenderCellParams) => {
        return (
            <Link
                onClick={() => {
                    if (params.value && onGeneClick) {
                        onGeneClick(params.value)
                    }
                }}
            >
                {params.value}
            </Link>
        )
    }

    // Define custom renderers
    const customRenderDataFrame = (field: string): Render | undefined => {
        if (field === 'names' && onGeneClick) {
            return geneNameRenderer
        }

        return undefined
    }

    return (
        <Dialog fullScreen open={openDialog} onClose={close} TransitionComponent={SlideUpTransition}>
            <AppBar sx={{ position: 'relative' }} color={'default'}>
                <Toolbar variant='dense'>
                    <IconButton edge='start' color='inherit' onClick={close} aria-label='close'>
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                        {name ?? `${caseValue} vs. ${controlValue}`}
                    </Typography>
                    {analysisUri && (
                        <Button component={Link} href={analysisUri} target={'_blank'} variant='contained'>
                            Go to Analysis Result
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <Stack sx={{ p: 2 }}>
                {degsDataFrame ? (
                    <>
                        <Box>
                            <VolcanoPlot
                                comparison={{
                                    pValueCutoff: 0.05,
                                    minAbsLogFC: 1,
                                }}
                                df={degsDataFrame}
                                width={Math.max(width - 30, 550)}
                                height={Math.max(height / 2 - 90, 300)}
                                disableLasso
                            />
                        </Box>
                        <Box>
                            <DataFrameGrid
                                df={degsDataFrame}
                                slots={{
                                    toolbar: GridToolbarWithSaveGenes,
                                }}
                                slotProps={{
                                    toolbar: {
                                        saveGenesCallback: (filteredGenes: string[]) => {
                                            setFilteredGenes(filteredGenes)
                                            setOpenCreateGeneListDialog(true)
                                        },
                                        extractGenesCallback: SingleGeneExtractorFactory('names'),
                                    },
                                }}
                                width={Math.max(width - 30, 550)}
                                height={Math.max(height / 2 - 80, 400)}
                                showBorder
                                columnCustomFilterOperators={{
                                    logfoldchanges: [absGreaterThanOrEqualFilterOperator, absLowerThanFilterOperator],
                                }}
                                customRenderer={customRenderDataFrame}
                            />
                            <CreateOrUpdateGeneListDialog
                                openDialog={openCreateGeneListDialog}
                                handleCloseDialog={() => {
                                    setOpenCreateGeneListDialog(false)
                                }}
                                externalGenes={filteredGenes}
                            />
                        </Box>
                    </>
                ) : (
                    <LoadingComponentData />
                )}
            </Stack>
        </Dialog>
    )
}
