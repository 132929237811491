import { apiSlice } from '../../../../app/apiSlice'
import {
    CellCompositionPreview,
    CellCompositionResult,
    ComputeStatisticsPreview,
    ComputeStatisticsResult,
    DifferentialExpressionPreview,
    DifferentialExpressionResult,
    DifferentialPathwayActivityPreview,
    DifferentialPathwayActivityResult,
    GeneSetEnrichmentPreview,
    GeneSetEnrichmentResult,
    ObsField,
    PlotMarkerGenesPreview,
    PlotMarkerGenesResult,
    WGCNAPreview,
    WGCNAResult,
} from '../../../../model/analysisCommands'
import {
    ComputationResult,
    DeleteComputationResultBulkRequest,
    DeleteComputationResultRequest,
    ExternalFile,
    RenameComputationResultRequest,
} from '../../../../model/model'

type KeyRequest = {
    analysisId: number
    key: string
}

type GetObsColumnRequest = {
    analysisId: number
    column: string
}

export const analysisResultsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        listResults: builder.query<ComputationResult[], number>({
            query: (analysisId) => ({
                url: '/private/analysis/result',
                method: 'GET',
                params: {
                    analysisId,
                },
            }),
        }),
        listResultFiles: builder.query<ExternalFile[], { analysisId: number; resultId: number }>({
            query: ({ analysisId, resultId }) => ({
                url: '/private/analysis/result/files',
                method: 'GET',
                params: {
                    analysisId,
                    resultId,
                },
            }),
        }),
        getResultFileDownloadURL: builder.query<string, { analysisId: number; resultId: number; fileName: string }>({
            query: ({ analysisId, resultId, fileName }) => ({
                url: '/private/analysis/result/file-download-url',
                method: 'GET',
                params: {
                    analysisId,
                    resultId,
                    fileName,
                },
            }),
        }),
        getObsColumn: builder.query<(string | number | boolean)[], GetObsColumnRequest>({
            query: (req) => ({
                url: '/private/analysis/result/obs-column',
                method: 'GET',
                params: {
                    analysisId: req.analysisId,
                    column: req.column,
                },
            }),
        }),
        getObsFields: builder.query<ObsField[], number>({
            query: (analysisId) => ({
                url: '/private/analysis/result/obs-fields',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getObsColumnMapping: builder.query<Record<string, Record<string, string>>, number>({
            query: (analysisId) => ({
                url: '/private/analysis/result/obs-column-mapping',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getVarCsvURL: builder.query<string, number>({
            query: (analysisId) => ({
                url: '/private/analysis/result/var-csv',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getDatasetGenes: builder.query<string[], number>({
            query: (analysisId) => ({
                url: '/private/analysis/result/dataset-genes',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getUmapColors: builder.query<Record<string, string[]>, number>({
            query: (analysisId) => ({
                url: '/private/analysis/result/umap-colors',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getDifferentialExpressionPreviews: builder.query<Record<string, DifferentialExpressionPreview>, number>({
            query: (analysisId) => ({
                url: '/private/analysis/result/differential-expression',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getDifferentialExpression: builder.query<DifferentialExpressionResult, KeyRequest>({
            query: (req) => ({
                url: `/private/analysis/result/differential-expression/${req.key}`,
                method: 'GET',
                params: {
                    analysisId: req.analysisId,
                },
            }),
        }),
        getDifferentialPathwayActivityPreviews: builder.query<
            Record<string, DifferentialPathwayActivityPreview>,
            number
        >({
            query: (analysisId) => ({
                url: '/private/analysis/result/differential-pathway-activity',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getDifferentialPathwayActivity: builder.query<DifferentialPathwayActivityResult, KeyRequest>({
            query: (req) => ({
                url: `/private/analysis/result/differential-pathway-activity/${req.key}`,
                method: 'GET',
                params: {
                    analysisId: req.analysisId,
                },
            }),
        }),
        getPlotMarkerGenesPreviews: builder.query<Record<string, PlotMarkerGenesPreview>, number>({
            query: (analysisId) => ({
                url: '/private/analysis/result/plot-marker-genes',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getPlotMarkerGenes: builder.query<PlotMarkerGenesResult, KeyRequest>({
            query: (req) => ({
                url: `/private/analysis/result/plot-marker-genes/${req.key}`,
                method: 'GET',
                params: {
                    analysisId: req.analysisId,
                },
            }),
        }),
        getComputeStatisticsPreviews: builder.query<Record<string, ComputeStatisticsPreview>, number>({
            query: (analysisId) => ({
                url: '/private/analysis/result/compute-statistics',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getComputeStatistics: builder.query<ComputeStatisticsResult, KeyRequest>({
            query: (req) => ({
                url: `/private/analysis/result/compute-statistics/${req.key}`,
                method: 'GET',
                params: {
                    analysisId: req.analysisId,
                },
            }),
        }),
        getGeneSetEnrichmentPreviews: builder.query<Record<string, GeneSetEnrichmentPreview>, number>({
            query: (analysisId) => ({
                url: '/private/analysis/result/gene-set-enrichment',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getGeneSetEnrichment: builder.query<GeneSetEnrichmentResult, KeyRequest>({
            query: (req) => ({
                url: `/private/analysis/result/gene-set-enrichment/${req.key}`,
                method: 'GET',
                params: {
                    analysisId: req.analysisId,
                },
            }),
        }),
        getPathwayActivityInferenceCsv: builder.query<string, number>({
            query: (analysisId) => ({
                url: '/private/analysis/result/pathway-activity-inference-csv',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getWGCNAPreviews: builder.query<Record<string, WGCNAPreview>, number>({
            query: (analysisId) => ({
                url: '/private/analysis/result/wgcna',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getWGCNA: builder.query<WGCNAResult, KeyRequest>({
            query: (req) => ({
                url: `/private/analysis/result/wgcna/${req.key}`,
                method: 'GET',
                params: {
                    analysisId: req.analysisId,
                },
            }),
        }),
        getCellCompositionPreviews: builder.query<Record<string, CellCompositionPreview>, number>({
            query: (analysisId) => ({
                url: '/private/analysis/result/cell-composition',
                method: 'GET',
                params: {
                    analysisId: analysisId,
                },
            }),
        }),
        getCellComposition: builder.query<CellCompositionResult, KeyRequest>({
            query: (req) => ({
                url: `/private/analysis/result/cell-composition/${req.key}`,
                method: 'GET',
                params: {
                    analysisId: req.analysisId,
                },
            }),
        }),
        renameComputationResult: builder.mutation<unknown, RenameComputationResultRequest>({
            query: (data) => ({
                url: '/private/analysis/rename-computation-result',
                method: 'POST',
                body: data,
            }),
        }),
        deleteComputationResult: builder.mutation<unknown, DeleteComputationResultRequest>({
            query: (data) => ({
                url: '/private/analysis/computation-result',
                method: 'DELETE',
                body: data,
            }),
        }),
        deleteComputationResultBulk: builder.mutation<unknown, DeleteComputationResultBulkRequest>({
            query: (data) => ({
                url: '/private/analysis/result/bulk',
                method: 'DELETE',
                body: data,
            }),
        }),
    }),
})

export const {
    useLazyListResultsQuery,
    useLazyListResultFilesQuery,
    useLazyGetResultFileDownloadURLQuery,
    useLazyGetObsColumnQuery,
    useLazyGetObsFieldsQuery,
    useLazyGetObsColumnMappingQuery,
    useLazyGetUmapColorsQuery,
    useLazyGetDifferentialExpressionPreviewsQuery,
    useLazyGetDifferentialExpressionQuery,
    useLazyGetDifferentialPathwayActivityPreviewsQuery,
    useLazyGetDifferentialPathwayActivityQuery,
    useLazyGetDatasetGenesQuery,
    useLazyGetGeneSetEnrichmentQuery,
    useLazyGetGeneSetEnrichmentPreviewsQuery,
    useLazyGetPathwayActivityInferenceCsvQuery,
    useLazyGetPlotMarkerGenesQuery,
    useLazyGetPlotMarkerGenesPreviewsQuery,
    useLazyGetComputeStatisticsQuery,
    useLazyGetComputeStatisticsPreviewsQuery,
    useLazyGetVarCsvURLQuery,
    useLazyGetWGCNAPreviewsQuery,
    useLazyGetWGCNAQuery,
    useLazyGetCellCompositionPreviewsQuery,
    useLazyGetCellCompositionQuery,
    useRenameComputationResultMutation,
    useDeleteComputationResultMutation,
    useDeleteComputationResultBulkMutation,
} = analysisResultsApiSlice
