import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

type RuntimeState = {
    exists: boolean | null
    proc: string | null
    instanceType: string | null
    createdAt: string | null
    connected: boolean | null
    maxIdleTimeHours: number | null
    loaded: boolean
}

const initialState = {
    exists: null,
    proc: null,
    instanceType: null,
    createdAt: null,
    connected: null,
    maxIdleTimeHours: null,
    loaded: false,
} as RuntimeState

const runtimeSlice = createSlice({
    name: 'runtime',
    initialState: initialState,
    reducers: {
        setRuntime: (
            state,
            {
                payload: { exists, proc, instanceType, createdAt, connected, maxIdleTimeHours },
            }: PayloadAction<{
                exists: boolean | null
                proc: string | null
                instanceType: string | null
                createdAt: string | null
                connected: boolean | null
                maxIdleTimeHours: number | null
            }>,
        ) => {
            state.loaded = true
            state.exists = exists
            state.proc = proc
            state.instanceType = instanceType
            state.createdAt = createdAt
            state.connected = connected
            state.maxIdleTimeHours = maxIdleTimeHours
        },
    },
})

export const { setRuntime } = runtimeSlice.actions

export default runtimeSlice.reducer

export const selectRuntime = (state: RootState) => state.runtime

export const selectRuntimeConnected = (state: RootState) => state.runtime.connected
