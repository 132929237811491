import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import * as React from 'react'
import { useAppSelector } from '../../app/hooks'
import { selectRuntime } from './runtimeSlice'
import Grid from '@mui/material/Grid'
import { DialogProps } from '../../model/model'
import { useSubmitOnEnter } from '../../hooks/useSubmitOnEnter'
import { useDeliberateDialogClose } from '../../hooks/useDeliberateDialogClose'

export default function RuntimeDetailsDialog(props: DialogProps) {
    const { t } = useTranslation()
    const runtimeDetails = useAppSelector(selectRuntime)

    useSubmitOnEnter(props.handleCloseDialog, props.openDialog)

    const close = useDeliberateDialogClose(props.handleCloseDialog)

    return (
        <Dialog
            open={props.openDialog}
            onClose={close}
            aria-labelledby='runtime-details-alert-dialog-title'
            aria-describedby='runtime-details-alert-dialog-description'
        >
            <DialogTitle id='runtime-details-alert-dialog-title'>{t('runtimeDetails')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        Status:
                    </Grid>
                    <Grid item xs={8}>
                        <>{runtimeDetails.exists ? 'Active' : 'Terminated'}</>
                    </Grid>
                    <Grid item xs={4}>
                        Connected:
                    </Grid>
                    <Grid item xs={8}>
                        <>{runtimeDetails.connected ? 'Yes' : 'No'}</>
                    </Grid>
                    <Grid item xs={4}>
                        Instance type:
                    </Grid>
                    <Grid item xs={8}>
                        {runtimeDetails.instanceType}
                    </Grid>
                    <Grid item xs={4}>
                        Max idle time (hours):
                    </Grid>
                    <Grid item xs={8}>
                        {runtimeDetails.maxIdleTimeHours}
                    </Grid>
                    <Grid item xs={4}>
                        Running since:
                    </Grid>
                    <Grid item xs={8}>
                        {runtimeDetails.createdAt}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleCloseDialog} color={'error'}>
                    {t('close')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
