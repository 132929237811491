import { Alert, Box, CircularProgress, FormControlLabel, Switch, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeliberateDialogClose } from '../../../../../../hooks/useDeliberateDialogClose'
import { DialogProps } from '../../../../../../model/model'
import CreateOrUpdateGeneListDialog from '../../../../../genelist/CreateOrUpdateGeneListDialog'
import GridToolbarWithSaveGenes from '../../../common/GridToolbarWithSaveGenes'
import { GeneSignatureOverlapEntry, GetGeneOverlapRequest, useGetGeneOverlapMutation } from './geneSignatureApiSlice'
import { debounce } from 'lodash'
import { SlideUpTransition } from '../../../../../../utils/transitions'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AppBar from '@mui/material/AppBar'
import { SingleGeneExtractorFactory } from '../../../../../../utils/grid'
import useWindowSize from '../../../../../../hooks/useWindowResize'
import { DataFrame } from 'danfojs'
import { HeatmapPlot } from '../../../common/HeatmapPlot'

export interface GeneSignatureOverlapDialogParams extends DialogProps {
    geneSignatureIds: number[]
    raw: boolean
}

export default function GeneSignatureOverlapDialog({
    geneSignatureIds,
    openDialog,
    handleCloseDialog,
    raw,
}: GeneSignatureOverlapDialogParams) {
    const [getGeneOverlapApi, response] = useGetGeneOverlapMutation()
    const [overlap, setOverlap] = useState<GeneSignatureOverlapEntry[] | null>(null)
    const [localRaw, setLocalRaw] = useState(raw)
    const { width = 0, height = 0 } = useWindowSize()

    const close = useDeliberateDialogClose(() => {
        handleCloseDialog()
        setOverlap([])
    })

    const debouncedGetGeneOverlapApi = useCallback(
        debounce((params) => {
            getGeneOverlapApi(params)
        }, 400),
        [],
    )

    useEffect(() => {
        if (!geneSignatureIds || geneSignatureIds.length < 2 || !openDialog) {
            return
        }
        const params = {
            geneSignatureIds: geneSignatureIds,
            pValThreshold: 0.05,
            minAbsLogFoldChange: 0,
            raw: localRaw,
        } as GetGeneOverlapRequest

        debouncedGetGeneOverlapApi(params)

        return () => {
            debouncedGetGeneOverlapApi.cancel()
        }
    }, [geneSignatureIds, openDialog, localRaw])

    useEffect(() => {
        if (response.data) {
            setOverlap(response.data)
        }
    }, [response])

    return (
        <Dialog fullScreen open={openDialog} onClose={close} TransitionComponent={SlideUpTransition}>
            <AppBar sx={{ position: 'relative' }} color={'default'}>
                <Toolbar variant='dense'>
                    <IconButton edge='start' color='inherit' onClick={close} aria-label='close'>
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                        <Typography sx={{ ml: 2 }} variant='h6' component='div'>
                            Gene Signature Overlap
                        </Typography>
                        <Typography sx={{ ml: 2 }} variant='h6' component='div'>
                            {geneSignatureIds.length} selected
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: 'flex', p: 2, width: '100%', flexDirection: 'column' }}>
                <Alert severity={'info'} sx={{ mb: 2 }}>
                    By default, the overlap is calculated using genes with a maximum FDR-adjusted p-value of 0.05. If
                    you enable the &quot;use unadjusted p-value&quot; option, the overlap will be calculated using genes
                    with a maximum unadjusted p-value of 0.05.
                </Alert>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={localRaw}
                                onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                    setLocalRaw(checked)
                                }}
                            />
                        }
                        label='Use unadjusted p-value'
                    />
                </Box>
                <GeneOverlapHeatmap entryList={overlap ?? []} loading={response.isLoading} width={width} height={400} />
                <GeneOverlapGrid
                    entryList={overlap ?? []}
                    loading={response.isLoading}
                    width={width}
                    height={Math.max(height - 600, 300)}
                />
            </Box>
        </Dialog>
    )
}

interface GeneOverlapGridParams {
    entryList: GeneSignatureOverlapEntry[]
    loading: boolean
    width: number
    height: number
}

const GeneOverlapHeatmap = ({ entryList, loading, width, height }: GeneOverlapGridParams) => {
    const df = useMemo(() => {
        if (!entryList || entryList.length == 0) {
            return null
        }
        const genes: string[] = []
        const signatures = Object.keys(entryList[0].signatureLogFoldChangePairs)
        const data: number[][] = []
        entryList.forEach((e) => {
            genes.push(e.gene)
            const row: number[] = []
            Object.keys(e.signatureLogFoldChangePairs).forEach((k) => {
                row.push(e.signatureLogFoldChangePairs[k])
            })
            data.push(row)
        })
        return new DataFrame(data, {
            index: genes.map((n) => `${n}\0`), // Force plotly to not cast to numbers as we can have IDs
            columns: signatures,
        }).T
    }, [entryList])

    return (
        <>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        height: `${height}px`,
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <CircularProgress size={30} />
                    <Typography>Loading heatmap</Typography>
                </Box>
            ) : (
                <>
                    {!df ? (
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                height: `${height}px`,
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <Alert severity={'info'}>
                                There are no overlapping genes to plot. Try using the unadjusted p-value option or
                                reduce the number of selected gene signatures.
                            </Alert>
                        </Box>
                    ) : (
                        <HeatmapPlot
                            plotDataFrame={df}
                            width={width - 30}
                            height={height}
                            xlabel={'Gene'}
                            ylabel={'Gene Signature'}
                            yangle={-45}
                            title={'Overlapping genes across gene signatures'}
                            symmetricalColorScale={true}
                        />
                    )}
                </>
            )}
        </>
    )
}

const GeneOverlapGrid = ({ entryList, loading, height }: GeneOverlapGridParams) => {
    const { t } = useTranslation()

    const rows = useMemo(() => {
        return entryList.map((e, idx) => {
            const row: Record<string, number | string> = {}
            row['id'] = idx
            row['gene'] = e.gene
            row['consistent'] = e.consistent ? 'Yes' : 'No'
            let logFoldChangeSum = 0
            Object.keys(e.signatureLogFoldChangePairs).forEach((k) => {
                row[k] = e.signatureLogFoldChangePairs[k].toFixed(4)
                logFoldChangeSum += e.signatureLogFoldChangePairs[k] as number
            })
            row['avg'] = (logFoldChangeSum / Object.keys(e.signatureLogFoldChangePairs).length).toFixed(4)
            return row
        })
    }, [entryList])

    const columns = useMemo(() => {
        const uniqueColumnNames = new Set(
            entryList.flatMap((e) => {
                return Object.keys(e.signatureLogFoldChangePairs)
            }),
        )
        const variableColumns = Array.from(uniqueColumnNames).map((c) => {
            return {
                field: c,
                type: 'number',
                headerName: c,
                groupable: false,
                width: 250,
            }
        })
        return [
            {
                field: 'gene',
                headerName: t('gene'),
                groupable: false,
                width: 100,
            },
            {
                field: 'consistent',
                headerName: t('consistent'),
                groupable: false,
                width: 100,
                type: 'singleSelect',
                valueOptions: ['Yes', 'No'],
            },
            {
                field: 'avg',
                headerName: t('avgLogFoldChange'),
                groupable: false,
                width: 100,
                type: 'number',
            },
            ...variableColumns,
        ] as GridColDef[]
    }, [entryList])

    const [openCreateGeneListDialog, setOpenCreateGeneListDialog] = useState(false)
    const [filteredGenes, setFilteredGenes] = useState<string[]>([])

    return (
        <Box sx={{ height: `${height}px` }}>
            <DataGridPremium
                sx={{
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#EEE',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        pt: 1,
                        pb: 1,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderRadius: 0,
                    },
                }}
                rows={rows}
                rowHeight={30}
                columns={columns}
                loading={loading}
                disableAggregation
                disableColumnSelector
                pageSizeOptions={[10, 25, 50, 100]}
                pagination={true}
                disableMultipleColumnsSorting
                slots={{
                    toolbar: GridToolbarWithSaveGenes,
                }}
                slotProps={{
                    toolbar: {
                        saveGenesCallback: (filteredGenes: string[]) => {
                            setFilteredGenes(filteredGenes)
                            setOpenCreateGeneListDialog(true)
                        },
                        extractGenesCallback: SingleGeneExtractorFactory('gene'),
                    },
                }}
            />
            <CreateOrUpdateGeneListDialog
                openDialog={openCreateGeneListDialog}
                handleCloseDialog={() => {
                    setOpenCreateGeneListDialog(false)
                }}
                externalGenes={filteredGenes}
            />
        </Box>
    )
}
