import { Button } from '@mui/material'
import Divider from '@mui/material/Divider'
import {
    gridFilteredSortedRowEntriesSelector,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridValidRowModel,
    ToolbarPropsOverrides,
    useGridApiContext,
} from '@mui/x-data-grid-premium'
import { useTranslation } from 'react-i18next'
import DnaIcon from '../../../../assets/icons/DNA'

function SaveGenesButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Button startIcon={<DnaIcon />} onClick={onClick} size={'small'} disabled={disabled}>
            {t('saveGenes')}
        </Button>
    )
}

declare module '@mui/x-data-grid-premium' {
    interface ToolbarPropsOverrides {
        saveGenesCallback: (filteredGenes: string[]) => void
        disableSaveGenes: boolean
        extractGenesCallback: (models: GridValidRowModel[]) => string[]
    }
}

function GridToolbarWithSaveGenes({
    saveGenesCallback,
    disableSaveGenes,
    extractGenesCallback,
}: ToolbarPropsOverrides) {
    const apiRef = useGridApiContext()

    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton
                slotProps={{ button: { nonce: undefined, onResize: undefined, onResizeCapture: undefined } }}
            />
            <GridToolbarDensitySelector
                slotProps={{ button: { nonce: undefined, onResize: undefined, onResizeCapture: undefined } }}
            />
            <GridToolbarExport />
            <Divider orientation={'vertical'} flexItem />
            <SaveGenesButton
                onClick={() => {
                    const genes = extractGenesCallback(gridFilteredSortedRowEntriesSelector(apiRef).map((e) => e.model))
                    saveGenesCallback(genes)
                }}
                disabled={disableSaveGenes}
            />
        </GridToolbarContainer>
    )
}

export default GridToolbarWithSaveGenes
