import { apiSlice } from '../../app/apiSlice'
import { RuntimeDetails, RuntimeOption } from '../../model/model'
import { RuntimeStatusInformation } from '../../model/webSocketCommands'

export type CreateRuntimeRequest = {
    runtimeOptionId: number
    maxIdleTimeHours: number
}

export type GetActiveRuntimeResponse = RuntimeStatusInformation

export type CreateRuntimeResponse = RuntimeDetails

export const runtimeApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRuntimeOptions: builder.query<RuntimeOption[], void>({
            query: () => ({
                url: '/private/runtime/get-options',
            }),
        }),
        getActiveRuntimeInfo: builder.query<GetActiveRuntimeResponse, void>({
            query: () => ({
                url: '/private/runtime/get-info',
            }),
        }),
        createRuntime: builder.mutation<CreateRuntimeResponse, CreateRuntimeRequest>({
            query: (req) => ({
                url: '/private/runtime/create',
                method: 'POST',
                body: req,
            }),
        }),
        terminateRuntime: builder.mutation<void, void>({
            query: (req) => ({
                url: '/private/runtime/terminate',
                method: 'POST',
                body: req,
            }),
        }),
    }),
})

export const {
    useGetRuntimeOptionsQuery,
    useLazyGetActiveRuntimeInfoQuery,
    useCreateRuntimeMutation,
    useTerminateRuntimeMutation,
    endpoints: { getRuntimeOptions, getActiveRuntimeInfo, createRuntime, terminateRuntime },
} = runtimeApiSlice
