import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { DashboardFilter, DialogProps, MetadataField, MetadataFieldType } from '../../../model/model'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import {
    Autocomplete,
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { SelectChangeEvent } from '@mui/material/Select'
import { receivedDashboardFilter, selectSelectedDashboardId } from '../dashboardSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { useGetDashboardFilterFieldQuery, useSaveDashboardFilterMutation } from './dashboardFiltersApiSlice'
import CheckboxSelect from '../../common/CheckboxSelect'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import OntologyForm from '../../common/OntologyForm'

function operatorRequiresList(type: MetadataFieldType, operator: string) {
    return type === MetadataFieldType.List && operator === 'in'
}

type DashboardFilterDialogProps = DialogProps & {
    filter?: DashboardFilter | null
}
export default function DashboardFilterDialog(props: DashboardFilterDialogProps) {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const dashboardId = useAppSelector(selectSelectedDashboardId)
    const [saveFilterApi] = useSaveDashboardFilterMutation()

    const [field, setField] = useState<MetadataField | null>(null)
    const [fieldError, setFieldError] = useState(false)
    const [operator, setOperator] = useState('')
    const [operatorError, setOperatorError] = useState(false)
    const [value, setValue] = useState('')
    const [valueList, setValueList] = useState<string[]>([])
    const [valueError, setValueError] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)

    const { data: responseFields } = useGetDashboardFilterFieldQuery()
    const fields = useMemo(() => responseFields?.fields, [responseFields])

    const valueAsList = useMemo(
        () => (field != null ? operatorRequiresList(field.type, operator) : false),
        [field, operator],
    )

    // Populate fields when editing filter.
    useEffect(() => {
        if (!fields || !props.openDialog) {
            return
        }
        if (!props.filter) {
            resetValues()
            return
        }

        const foundField = fields.find((f) => f.name == props.filter?.field)
        if (!foundField) {
            return
        }

        setField(foundField)
        setOperator(props.filter.operator)

        if (operatorRequiresList(foundField.type, props.filter.operator)) {
            setValueList(props.filter.value.split(','))
        } else {
            setValue(props.filter.value)
        }
    }, [props.filter, fields, props.openDialog])

    const resetErrors = () => {
        setFieldError(false)
        setOperatorError(false)
        setValueError(false)
    }

    const resetValues = () => {
        setField(null)
        setOperator('')
        setValue('')
        setValueList([])
    }

    const validateForm = () => {
        resetErrors()
        let valid = true
        if (!field) {
            setFieldError(true)
            valid = false
        }
        if (!operator) {
            setOperatorError(true)
            valid = false
        }
        if (!valueAsList && !value) {
            setValueError(true)
            valid = false
        }
        if (valueAsList && !valueList) {
            setValueError(true)
            valid = false
        }
        return valid
    }

    const submit = async () => {
        if (!dashboardId || !field || !validateForm()) {
            return
        }

        setFormSubmitted(true)
        try {
            const res = await saveFilterApi({
                dashboardId: dashboardId,
                filter: {
                    id: props.filter ? props.filter.id : null,
                    field: field.name,
                    operator: operator,
                    value: valueAsList ? valueList.join(',') : value,
                } as DashboardFilter & { id: number | null },
            }).unwrap()

            dispatch(
                receivedDashboardFilter({
                    dashboardId: res.dashboardId,
                    filter: res.filter,
                }),
            )

            props.handleCloseDialog()
        } finally {
            setFormSubmitted(false)
        }
    }

    useSubmitOnEnter(submit, props.openDialog)

    const close = useDeliberateDialogClose(() => {
        setFormSubmitted(false)
        resetValues()
        resetErrors()
        props.handleCloseDialog()
    })

    const handleOperatorChange = (event: SelectChangeEvent) => {
        setOperator(event.target.value)
    }

    const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent) => {
        setValue(event.target.value)
    }

    const handleValueListChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event
        setValueList(typeof value === 'string' ? value.split(',') : value)
    }

    return (
        <Dialog open={props.openDialog} onClose={close} maxWidth={'sm'}>
            <DialogTitle>{props.filter ? 'Edit Filter' : 'Add Filter'}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '400px' }}>
                    <Autocomplete
                        sx={{ width: '100%', mt: 2 }}
                        options={fields ?? []}
                        getOptionLabel={(f) => t(f.name)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Field'
                                error={fieldError}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off', // disable autocomplete and autofill
                                }}
                            />
                        )}
                        value={field}
                        onChange={(_, newValue: MetadataField | null) => {
                            setField(newValue)
                            setOperator('')
                            setValue('')
                        }}
                    />
                    <FormControl sx={{ width: '100%', mt: 2 }}>
                        <InputLabel required>{t('operator')}</InputLabel>
                        <Select
                            label={t('operator')}
                            value={operator}
                            onChange={handleOperatorChange}
                            error={operatorError}
                            required
                            fullWidth
                        >
                            {field?.type == MetadataFieldType.Numeric && [
                                <MenuItem key={'field-0'} value='='>
                                    =
                                </MenuItem>,
                                <MenuItem key={'field-1'} value='!='>
                                    !=
                                </MenuItem>,
                                <MenuItem key={'field-2'} value='>'>
                                    &gt;
                                </MenuItem>,
                                <MenuItem key={'field-3'} value='>='>
                                    &gt;=
                                </MenuItem>,
                                <MenuItem key={'field-4'} value='<'>
                                    &lt;
                                </MenuItem>,
                                <MenuItem key={'field-5'} value='<='>
                                    &lt;=
                                </MenuItem>,
                            ]}
                            {field?.type == MetadataFieldType.String && [
                                <MenuItem key={'field-0'} value='is'>
                                    is
                                </MenuItem>,
                                <MenuItem key={'field-1'} value='is not'>
                                    is not
                                </MenuItem>,
                                <MenuItem key={'field-2'} value='contains'>
                                    contains
                                </MenuItem>,
                                <MenuItem key={'field-3'} value='does not contain'>
                                    does not contain
                                </MenuItem>,
                            ]}
                            {field?.type == MetadataFieldType.List && [
                                <MenuItem key={'field-0'} value='is'>
                                    is
                                </MenuItem>,
                                <MenuItem key={'field-1'} value='is not'>
                                    is not
                                </MenuItem>,
                                <MenuItem key={'field-2'} value='in'>
                                    in
                                </MenuItem>,
                            ]}
                            {field?.type == MetadataFieldType.Ontology && [
                                <MenuItem key={'field-0'} value='is'>
                                    is
                                </MenuItem>,
                                <MenuItem key={'field-1'} value='is not'>
                                    is not
                                </MenuItem>,
                                <MenuItem key={'field-2'} value='contains'>
                                    contains
                                </MenuItem>,
                                <MenuItem key={'field-3'} value='does not contain'>
                                    does not contain
                                </MenuItem>,
                                <MenuItem key={'field-4'} value='in'>
                                    in
                                </MenuItem>,
                            ]}
                        </Select>
                    </FormControl>
                    {field?.type == MetadataFieldType.Numeric && (
                        <TextField
                            label={t('value')}
                            sx={{ mt: 2 }}
                            type='number'
                            value={value}
                            onChange={handleValueChange}
                            fullWidth
                            required
                        />
                    )}
                    {field?.type == MetadataFieldType.String && (
                        <TextField
                            label={t('value')}
                            sx={{ mt: 2 }}
                            type='text'
                            value={value}
                            onChange={handleValueChange}
                            fullWidth
                            required
                        />
                    )}
                    {field?.type == MetadataFieldType.Ontology && operator && field?.ontology && (
                        <OntologyForm
                            ontologyId={field.ontology.id}
                            operator={operator}
                            value={value}
                            setValue={(value) => setValue(value)}
                        />
                    )}
                    {field?.type == MetadataFieldType.List && (
                        <FormControl sx={{ mt: 2 }}>
                            <InputLabel required>{t('value')}</InputLabel>
                            {!valueAsList ? (
                                <Select
                                    label={t('value')}
                                    value={value}
                                    onChange={handleValueChange}
                                    error={valueError}
                                    required
                                    fullWidth
                                >
                                    {field?.options?.map((o, idx) => {
                                        return (
                                            <MenuItem key={`field-${idx}`} value={o}>
                                                {o}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            ) : (
                                <CheckboxSelect
                                    label={t('value')}
                                    values={valueList}
                                    onChange={handleValueListChange}
                                    error={valueError}
                                    options={field?.options ?? []}
                                />
                            )}
                        </FormControl>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                {!formSubmitted ? (
                    <Button onClick={submit} autoFocus>
                        {t('submit')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
