import { Box } from '@mui/material'
import { DataGridPremium, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid-premium'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'usehooks-ts'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import useStoredFilterModel from '../../../hooks/useStoredFilterModel'
import { selectUserLicense } from '../../auth/authSlice'
import { receivedAppMessage } from '../../dashboard/appMessageSlice'
import CreateOrUpdateUserDialog from './CreateOrUpdateUserDialog'
import UserGridToolbar from './UserGridToolbar'
import { useChangeUsersActivationStatusMutation, useListUsersQuery } from './adminUserApiSlice'
import {
    receivedUsersActivationStatusUpdate,
    receivedUsersList,
    selectTotalUsers,
    selectUserRows,
    selectUsersByIds,
} from './adminUserSlice'

export default function UserList() {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const userRows = useAppSelector(selectUserRows)
    const totalCount = useAppSelector(selectTotalUsers)
    const license = useAppSelector(selectUserLicense)
    const [openCreateOrUpdateUserDialog, setOpenCreateOrUpdateUserDialog] = useState(false)
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
    const selectedUsers = useAppSelector((state) => {
        return selectUsersByIds(state, selectionModel)
    })
    const { data: response, isFetching } = useListUsersQuery()
    const [changeActivationStatus] = useChangeUsersActivationStatusMutation()
    const { width = 0 } = useWindowSize()

    const [filterModel, setFilterModel] = useStoredFilterModel('user_list', {
        items: [],
        quickFilterLogicOperator: undefined,
        quickFilterValues: [],
    })

    const renderActivationStatusCell = (params: GridRenderCellParams) => {
        const active = params.value as boolean
        return active ? 'Yes' : 'No'
    }

    const columns = [
        {
            field: 'firstName',
            headerName: t('firstName'),
            groupable: false,
            width: 180,
        },
        {
            field: 'lastName',
            headerName: t('lastName'),
            groupable: false,
            width: 180,
        },
        {
            field: 'email',
            headerName: t('email'),
            groupable: false,
            width: 300,
        },
        {
            field: 'phone',
            headerName: t('phone'),
            groupable: false,
            width: 200,
        },
        {
            field: 'role',
            headerName: t('role'),
            groupable: false,
            sortable: false,
            width: 300,
        },
        {
            field: 'active',
            headerName: t('active'),
            groupable: false,
            width: 150,
            renderCell: renderActivationStatusCell,
        },
        {
            field: 'createdAt',
            headerName: t('createdAt'),
            groupable: false,
            filterable: false,
            width: Math.max((width - 1310) / 2 - 26, 150),
        },
        {
            field: 'updatedAt',
            headerName: t('updatedAt'),
            groupable: false,
            filterable: false,
            width: Math.max((width - 1310) / 2 - 26, 150),
        },
    ]

    useEffect(() => {
        if (!response) {
            return
        }
        dispatch(
            receivedUsersList({
                userList: response.userList,
            }),
        )
    }, [response])

    const seatsLeft = useMemo(() => {
        if (!license) {
            return 0
        }
        return license.seats - userRows.filter((u) => u.active).length
    }, [userRows, license])

    const disableActivate = useMemo(() => {
        if (!selectedUsers) {
            return true
        }
        let disable = false
        selectedUsers.forEach((u) => {
            if (!u) {
                return true
            }
            disable = disable || u.active
        })
        if (selectedUsers.length > seatsLeft) {
            return true
        }
        return disable
    }, [selectionModel, userRows])

    const disableInactivate = useMemo(() => {
        if (!selectedUsers) {
            return true
        }
        let disable = false
        selectedUsers.forEach((u) => {
            if (!u) {
                return true
            }
            disable = disable || !u.active
        })
        return disable
    }, [selectionModel, userRows])

    const changeUsersActivationStatus = (active: boolean) => {
        changeActivationStatus({
            userIdList: selectionModel as number[],
            active: active,
        })
            .unwrap()
            .then((resp) => {
                dispatch(
                    receivedUsersActivationStatusUpdate({
                        userIdList: resp.userIdList,
                        active: resp.active,
                    }),
                )
                dispatch(
                    receivedAppMessage({
                        type: 'success',
                        message: active
                            ? 'The selected users were successfully activated.'
                            : 'The selected users were successfully inactivated.',
                    }),
                )
            })
    }

    return (
        <Box sx={{ width: '100%' }}>
            <DataGridPremium
                sx={{
                    border: 0,
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#EEE',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        pt: 1,
                        pb: 1,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderRadius: 0,
                    },
                }}
                rows={userRows}
                rowCount={totalCount}
                columns={columns}
                disableAggregation
                disableColumnSelector
                loading={isFetching}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel)
                }}
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
                checkboxSelection
                keepNonExistentRowsSelected
                pageSizeOptions={[10, 25, 50, 100]}
                pagination={true}
                disableMultipleColumnsSorting
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                }}
                slots={{
                    toolbar: UserGridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        createCallback: () => {
                            setSelectionModel([])
                            setOpenCreateOrUpdateUserDialog(true)
                        },
                        editCallback: () => {
                            setOpenCreateOrUpdateUserDialog(true)
                        },
                        activateCallback: () => {
                            changeUsersActivationStatus(true)
                        },
                        inactivateCallback: () => {
                            changeUsersActivationStatus(false)
                        },
                        disableCreate: seatsLeft <= 0,
                        disableEdit: selectionModel.length != 1,
                        disableActivate: selectionModel.length == 0 || disableActivate,
                        disableInactivate: selectionModel.length == 0 || disableInactivate,
                    },
                }}
            />
            <CreateOrUpdateUserDialog
                openDialog={openCreateOrUpdateUserDialog}
                userId={selectionModel.length == 1 ? selectionModel[0] : undefined}
                handleCloseDialog={() => {
                    setOpenCreateOrUpdateUserDialog(false)
                }}
            />
        </Box>
    )
}
