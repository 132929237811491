import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getBaseHttpURL } from '../utils/platform'
import { RootState } from './store'

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: getBaseHttpURL(),
        prepareHeaders: (headers, { getState }) => {
            // By default, if we have a token in the store, let's use that for authenticated requests
            const token = getToken(getState() as RootState)
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        },
    }),
    tagTypes: ['Role'],
    endpoints: () => ({}),
})

const getToken = (state: RootState) => {
    return state.auth.token
}
