import { Alert, Autocomplete, Box, FormControlLabel, Switch, TextField, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeliberateDialogClose } from '../../../../../../hooks/useDeliberateDialogClose'
import { DialogProps, SimilarityMetricType } from '../../../../../../model/model'
import { useLazyGetSimilarityMatrixQuery } from './geneSignatureApiSlice'
import { DataFrame } from 'danfojs'
import { SlideUpTransition } from '../../../../../../utils/transitions'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AppBar from '@mui/material/AppBar'
import { HeatmapPlot } from '../../../common/HeatmapPlot'
import LoadingComponentData from '../../../common/LoadingComponentData'

export interface GeneSignatureSimilarityMatrixDialogParams extends DialogProps {
    geneSignatureIds: number[]
    raw: boolean
}

export default function GeneSignatureSimilarityMatrixDialog({
    geneSignatureIds,
    openDialog,
    handleCloseDialog,
    raw,
}: GeneSignatureSimilarityMatrixDialogParams) {
    const { t } = useTranslation()
    const [getSimilarityMatrixApi] = useLazyGetSimilarityMatrixQuery()
    const [similarityMatrixDataFrame, setSimilarityMatrixDataFrame] = useState<DataFrame | null>(null)
    const [similarityMatrixOverlap, setSimilarityMatrixOverlap] = useState<number[][] | null>(null)
    const [metric, setMetric] = useState<SimilarityMetricType | null>('cosine')
    const [localRaw, setLocalRaw] = useState(raw)

    const close = useDeliberateDialogClose(() => {
        handleCloseDialog()
        setSimilarityMatrixDataFrame(null)
    })

    useEffect(() => {
        setSimilarityMatrixDataFrame(null)
        if (!geneSignatureIds || geneSignatureIds.length < 2 || !openDialog || !metric) {
            return
        }

        getSimilarityMatrixApi({
            geneSignatureIds: geneSignatureIds,
            raw: localRaw,
            metric: metric,
        })
            .unwrap()
            .then((resp) => {
                const df = new DataFrame(resp.data, { columns: resp.header })
                df.addColumn('experiment', resp.header, { inplace: true })
                df.setIndex({ column: 'experiment', inplace: true, drop: true })
                setSimilarityMatrixDataFrame(df)

                setSimilarityMatrixOverlap(resp.overlap)
            })
    }, [geneSignatureIds, openDialog, localRaw, metric])

    return (
        <Dialog fullScreen open={openDialog} onClose={close} TransitionComponent={SlideUpTransition}>
            <AppBar sx={{ position: 'relative' }} color={'default'}>
                <Toolbar variant='dense'>
                    <IconButton edge='start' color='inherit' onClick={close} aria-label='close'>
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                        <Typography sx={{ ml: 2 }} variant='h6' component='div'>
                            Gene Signature Similarity Matrix
                        </Typography>
                        <Typography sx={{ ml: 2 }} variant='h6' component='div'>
                            {geneSignatureIds.length} selected
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: 'flex', p: 2, width: '100%', flexDirection: 'column' }}>
                <Alert severity={'info'} sx={{ mb: 2 }}>
                    By default, the cosine similarity and Pearson correlation coefficient are calculated using
                    overlapping genes with a maximum FDR-adjusted p-value of 0.05. If you enable the &quot;use
                    unadjusted p-value&quot; option, the calculations will instead use overlapping genes with a maximum
                    unadjusted p-value of 0.05.
                </Alert>
                <Box sx={{ mt: 2, mb: 2, display: 'flex', gap: 2, width: '100%' }}>
                    <Autocomplete
                        sx={{ width: '300px', mr: 1 }}
                        options={['cosine' as SimilarityMetricType, 'pearson' as SimilarityMetricType]}
                        getOptionLabel={(o) => t(o)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Metric'
                                size='small'
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off', // disable autocomplete and autofill
                                }}
                                required
                            />
                        )}
                        value={metric}
                        onChange={(_, newValue: SimilarityMetricType | null) => {
                            setMetric(newValue)
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={localRaw}
                                onChange={(_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                    setLocalRaw(checked)
                                }}
                            />
                        }
                        label='Use unadjusted p-value'
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {similarityMatrixDataFrame ? (
                        <HeatmapPlot
                            plotDataFrame={similarityMatrixDataFrame}
                            width={window.innerWidth - 100}
                            height={window.innerHeight - 220}
                            xlabel={'Gene Signature'}
                            ylabel={'Gene Signature'}
                            title={'Similarity Matrix'}
                            hoverInfo={{
                                zlabel: 'score',
                                customData: similarityMatrixOverlap,
                                customDataLabel: 'overlapping genes',
                            }}
                        />
                    ) : (
                        <LoadingComponentData />
                    )}
                </Box>
            </Box>
        </Dialog>
    )
}
