import { wsSlice } from '../../app/wsSlice'
import { setRuntime } from './runtimeSlice'

wsSlice.injectSelectHandlers(
    (m) => m.runtimeStatusInformation,
    [
        (p, dispatch) => {
            dispatch(
                setRuntime({
                    connected: p.online,
                    exists: p.exists,
                    proc: p.proc,
                    instanceType: p.instanceType,
                    createdAt: p.createdAt ? p.createdAt.parseAndFormatDate() : '',
                    maxIdleTimeHours: p.maxIdleTimeHours,
                }),
            )
        },
    ],
)
