import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataFrame, Series } from 'danfojs'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { RootState } from '../../../../app/store'
import {
    CellCompositionPreview,
    ComputeStatisticsPreview,
    DifferentialExpressionPreview,
    DifferentialPathwayActivityPreview,
    GeneSetEnrichmentPreview,
    MicroarrayAnalysisResultData,
    ObsField,
    PlotMarkerGenesPreview,
    RnaSeqAnalysisResultData,
    ScRnaSeqAnalysisResultData,
    WGCNAPreview,
} from '../../../../model/analysisCommands'
import {
    Component,
    ComputeStatistics,
    DifferentialExpression,
    DifferentialPathwayActivity,
    GeneSetEnrichment,
    PlotMarkerGenes,
    ScRnaSeqCellComposition,
    WeightedGeneCoExpressionNetworkAnalysis,
} from '../../../../model/analysisComponents'
import { Analysis } from '../../../../model/model'
import { LRUSet } from '../../../../utils/lruSet'
import {
    useLazyGetCellCompositionPreviewsQuery,
    useLazyGetComputeStatisticsPreviewsQuery,
    useLazyGetDatasetGenesQuery,
    useLazyGetDifferentialExpressionPreviewsQuery,
    useLazyGetDifferentialPathwayActivityPreviewsQuery,
    useLazyGetGeneSetEnrichmentPreviewsQuery,
    useLazyGetObsColumnMappingQuery,
    useLazyGetObsColumnQuery,
    useLazyGetObsFieldsQuery,
    useLazyGetPathwayActivityInferenceCsvQuery,
    useLazyGetPlotMarkerGenesPreviewsQuery,
    useLazyGetUmapColorsQuery,
    useLazyGetVarCsvURLQuery,
    useLazyGetWGCNAPreviewsQuery,
} from './analysisResultsApiSlice'

type AllResultData = ScRnaSeqAnalysisResultData & RnaSeqAnalysisResultData & MicroarrayAnalysisResultData

type ResultsState = {
    analysisId: number | null
    reloadObs: boolean
    resultData: AllResultData | null
}

const initialState = {
    analysisId: null,
    reloadObs: false,
    resultData: null,
} as ResultsState

const analysisResultsSlice = createSlice({
    name: 'analysisResultsHolder',
    initialState: initialState,
    reducers: {
        receivedAnalysis: (state, { payload: { analysis } }: PayloadAction<{ analysis: Analysis }>) => {
            state.analysisId = analysis.id
            state.resultData = {
                normalized: analysis.normalized,
                umapComputed: analysis.umapComputed,
            } as AllResultData
        },
        receivedReloadObs: (
            state,
            { payload: { analysisId, reload } }: PayloadAction<{ analysisId?: number; reload: boolean }>,
        ) => {
            if (state.analysisId === analysisId) {
                state.reloadObs = reload
            }
        },
        receivedUmapColors: (
            state,
            {
                payload: { analysisId, umapColors },
            }: PayloadAction<{ analysisId?: number; umapColors?: { [p: string]: string[] } }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && umapColors) {
                state.resultData.umapColors = umapColors
            }
        },
        receivedObsColumnMapping: (
            state,
            {
                payload: { analysisId, obsColumnMapping },
            }: PayloadAction<{ analysisId?: number; obsColumnMapping?: Record<string, Record<string, string>> }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && obsColumnMapping) {
                state.resultData.obsColumnMapping = obsColumnMapping
            }
        },
        receivedObsFields: (
            state,
            { payload: { analysisId, obsFields } }: PayloadAction<{ analysisId?: number; obsFields?: ObsField[] }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && obsFields) {
                state.resultData.obsFields = obsFields
            }
        },
        receivedVarCsvUrl: (
            state,
            { payload: { analysisId, varCsvUrl } }: PayloadAction<{ analysisId?: number; varCsvUrl?: string | null }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && varCsvUrl) {
                state.resultData.varCsvUrl = varCsvUrl
            }
        },
        receivedCellCompositionPreviews: (
            state,
            {
                payload: { analysisId, cellCompositionPreviews },
            }: PayloadAction<{
                analysisId?: number
                cellCompositionPreviews?: Record<string, CellCompositionPreview>
            }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && cellCompositionPreviews) {
                state.resultData.cellCompositionPreviews = cellCompositionPreviews
            }
        },
        receivedDifferentialExpressionPreviews: (
            state,
            {
                payload: { analysisId, differentialExpressionPreviews },
            }: PayloadAction<{
                analysisId?: number
                differentialExpressionPreviews?: Record<string, DifferentialExpressionPreview>
            }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && differentialExpressionPreviews) {
                state.resultData.differentialExpressionPreviews = differentialExpressionPreviews
            }
        },
        receivedDifferentialPathwayActivityPreviews: (
            state,
            {
                payload: { analysisId, differentialPathwayActivityPreviews },
            }: PayloadAction<{
                analysisId?: number
                differentialPathwayActivityPreviews?: Record<string, DifferentialPathwayActivityPreview>
            }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && differentialPathwayActivityPreviews) {
                state.resultData.differentialPathwayActivityPreviews = differentialPathwayActivityPreviews
            }
        },
        receivedPlotMarkerGenesPreviews: (
            state,
            {
                payload: { analysisId, plotMarkerGenesPreviews },
            }: PayloadAction<{
                analysisId?: number
                plotMarkerGenesPreviews?: Record<string, PlotMarkerGenesPreview>
            }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && plotMarkerGenesPreviews) {
                state.resultData.plotMarkerGenesPreviews = plotMarkerGenesPreviews
            }
        },
        receivedComputeStatisticsPreviews: (
            state,
            {
                payload: { analysisId, computeStatisticsPreviews },
            }: PayloadAction<{
                analysisId?: number
                computeStatisticsPreviews?: Record<string, ComputeStatisticsPreview>
            }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && computeStatisticsPreviews) {
                state.resultData.computeStatisticsPreviews = computeStatisticsPreviews
            }
        },
        receivedGeneSetEnrichmentPreviews: (
            state,
            {
                payload: { analysisId, geneSetEnrichmentPreviews },
            }: PayloadAction<{
                analysisId?: number
                geneSetEnrichmentPreviews?: Record<string, GeneSetEnrichmentPreview>
            }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && geneSetEnrichmentPreviews) {
                state.resultData.geneSetEnrichmentPreviews = geneSetEnrichmentPreviews
            }
        },
        receivedPathwayActivityInferenceResults: (
            state,
            {
                payload: { analysisId, pathwayActivityInferenceResultsCsvUrl },
            }: PayloadAction<{
                analysisId?: number
                pathwayActivityInferenceResultsCsvUrl?: string | null
            }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && pathwayActivityInferenceResultsCsvUrl) {
                state.resultData.pathwayActivityInferenceResultsCsvUrl = pathwayActivityInferenceResultsCsvUrl
            }
        },
        receivedUmapComputed: (
            state,
            {
                payload: { analysisId, umapComputed },
            }: PayloadAction<{
                analysisId?: number
                umapComputed?: boolean
            }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && umapComputed) {
                state.resultData.umapComputed = umapComputed
            }
        },
        receivedNormalizeResults: (
            state,
            {
                payload: { analysisId, normalized },
            }: PayloadAction<{
                analysisId?: number
                normalized?: boolean
            }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && normalized) {
                state.resultData.normalized = normalized
            }
        },
        renameComputationResult: (
            state,
            {
                payload: { analysisId, key, name, component },
            }: PayloadAction<{
                analysisId: number
                key: string
                name: string
                component: Component
            }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData) {
                switch (component) {
                    case ScRnaSeqCellComposition:
                        state.resultData.cellCompositionPreviews[key].name = name
                        break
                    case DifferentialExpression:
                        state.resultData.differentialExpressionPreviews[key].name = name
                        break
                    case DifferentialPathwayActivity:
                        state.resultData.differentialPathwayActivityPreviews[key].name = name
                        break
                    case PlotMarkerGenes:
                        state.resultData.plotMarkerGenesPreviews[key].name = name
                        break
                    case GeneSetEnrichment:
                        state.resultData.geneSetEnrichmentPreviews[key].name = name
                        break
                    case WeightedGeneCoExpressionNetworkAnalysis:
                        state.resultData.wgcnaPreviews[key].name = name
                        break
                    case ComputeStatistics:
                        state.resultData.computeStatisticsPreviews[key].name = name
                        break
                }
            }
        },
        deleteComputationResult: (
            state,
            {
                payload: { analysisId, key, component },
            }: PayloadAction<{
                analysisId: number
                key: string
                component: Component
            }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData) {
                switch (component) {
                    case ScRnaSeqCellComposition:
                        delete state.resultData.cellCompositionPreviews[key]
                        break
                    case DifferentialExpression:
                        delete state.resultData.differentialExpressionPreviews[key]
                        break
                    case DifferentialPathwayActivity:
                        delete state.resultData.differentialPathwayActivityPreviews[key]
                        break
                    case PlotMarkerGenes:
                        delete state.resultData.plotMarkerGenesPreviews[key]
                        break
                    case GeneSetEnrichment:
                        delete state.resultData.geneSetEnrichmentPreviews[key]
                        break
                    case WeightedGeneCoExpressionNetworkAnalysis:
                        delete state.resultData.wgcnaPreviews[key]
                        break
                    case ComputeStatistics:
                        delete state.resultData.computeStatisticsPreviews[key]
                        break
                }
            }
        },
        receivedDatasetGenes: (
            state,
            {
                payload: { analysisId, datasetGenes },
            }: PayloadAction<{
                analysisId?: number
                datasetGenes?: string[] | undefined | null
            }>,
        ) => {
            if (state.analysisId === analysisId && state.resultData && datasetGenes) {
                state.resultData.datasetGenes = datasetGenes
            }
        },
        receivedWGCNAPreviews: (
            state,
            {
                payload: { analysisId, wgcnaPreviews },
            }: PayloadAction<{
                analysisId?: number
                wgcnaPreviews?: Record<string, WGCNAPreview>
            }>,
        ) => {
            if (state.analysisId == analysisId && state.resultData && wgcnaPreviews) {
                state.resultData.wgcnaPreviews = wgcnaPreviews
            }
        },
        resetResults: () => initialState,
    },
})

export const {
    receivedAnalysis,
    receivedReloadObs,
    receivedUmapColors,
    receivedObsColumnMapping,
    receivedVarCsvUrl,
    receivedObsFields,
    receivedCellCompositionPreviews,
    receivedDifferentialExpressionPreviews,
    receivedDifferentialPathwayActivityPreviews,
    receivedPlotMarkerGenesPreviews,
    receivedComputeStatisticsPreviews,
    receivedGeneSetEnrichmentPreviews,
    receivedPathwayActivityInferenceResults,
    receivedUmapComputed,
    receivedNormalizeResults,
    renameComputationResult,
    deleteComputationResult,
    receivedDatasetGenes,
    receivedWGCNAPreviews,
    resetResults,
} = analysisResultsSlice.actions

// We query the data when needed but use slice state for result data as it can be updated by ws.

interface unwraper<T> {
    unwrap: () => Promise<T>
}

interface apiInfo {
    isFetching: boolean
}

type queryer<T> = [(analysisId: number) => unwraper<T>, apiInfo, unknown]

function useResultTemplate<T>(
    selector: (state: ResultsState) => T | null | undefined,
    queryer: () => queryer<T>,
    receiver: (analysisId: number, data: T) => Action,
): [T | undefined, boolean] {
    const dispatch = useAppDispatch()
    const analysisId = useAppSelector((state) => state.analysisResultsHolder.analysisId)

    const result = useAppSelector((rootState) => selector(rootState.analysisResultsHolder))
    const [api, { isFetching }] = queryer()

    useEffect(() => {
        if (!analysisId) {
            return
        }
        const fetchSetData = async (analysisId: number) => {
            const data = await api(analysisId).unwrap()
            dispatch(receiver(analysisId, data))
        }

        fetchSetData(analysisId).catch(console.error)
    }, [analysisId, api])

    return [result ?? undefined, isFetching]
}

function useResultModifiedTemplate<T, R>(
    selector: (state: ResultsState) => T | null | undefined,
    queryer: () => queryer<T>,
    receiver: (analysisId: number, data: T) => Action,
    modifier: (from: T | undefined | null) => R,
): [R, boolean] {
    const [data, isLoading] = useResultTemplate(selector, queryer, receiver)
    const modified = useMemo(() => {
        return modifier(data)
    }, [data, modifier])

    return [modified, isLoading]
}

export const usePreviewCellComposition = () =>
    useResultTemplate(
        (state) => state.resultData?.cellCompositionPreviews,
        useLazyGetCellCompositionPreviewsQuery,
        (analysisId, cellCompositionPreviews) =>
            receivedCellCompositionPreviews({ analysisId, cellCompositionPreviews }),
    )

export const useResultVarCsvURL = () =>
    useResultTemplate(
        (state) => state.resultData?.varCsvUrl,
        useLazyGetVarCsvURLQuery,
        (analysisId, varCsvUrl) => receivedVarCsvUrl({ analysisId, varCsvUrl }),
    )

export const useResultObsFields = () =>
    useResultTemplate(
        (state) => state.resultData?.obsFields,
        useLazyGetObsFieldsQuery,
        (analysisId, obsFields) => receivedObsFields({ analysisId, obsFields }),
    )

export const useResultUmapColors = () =>
    useResultTemplate(
        (state) => state.resultData?.umapColors,
        useLazyGetUmapColorsQuery,
        (analysisId, umapColors) => receivedUmapColors({ analysisId, umapColors }),
    )

export const useResultObsColumnMapping = () =>
    useResultTemplate(
        (state) => state.resultData?.obsColumnMapping,
        useLazyGetObsColumnMappingQuery,
        (analysisId, obsColumnMapping) => receivedObsColumnMapping({ analysisId, obsColumnMapping }),
    )

export const usePreviewDifferentialExpression = () =>
    useResultTemplate(
        (state) => state.resultData?.differentialExpressionPreviews,
        useLazyGetDifferentialExpressionPreviewsQuery,
        (analysisId, differentialExpressionPreviews) =>
            receivedDifferentialExpressionPreviews({ analysisId, differentialExpressionPreviews }),
    )

export const usePreviewDifferentialPathwayActivity = () =>
    useResultTemplate(
        (state) => state.resultData?.differentialPathwayActivityPreviews,
        useLazyGetDifferentialPathwayActivityPreviewsQuery,
        (analysisId, differentialPathwayActivityPreviews) =>
            receivedDifferentialPathwayActivityPreviews({ analysisId, differentialPathwayActivityPreviews }),
    )

export const usePreviewPlotMarkerGenes = () =>
    useResultTemplate(
        (state) => state.resultData?.plotMarkerGenesPreviews,
        useLazyGetPlotMarkerGenesPreviewsQuery,
        (analysisId, plotMarkerGenesPreviews) =>
            receivedPlotMarkerGenesPreviews({ analysisId, plotMarkerGenesPreviews }),
    )

export const usePreviewComputeStatistics = () =>
    useResultTemplate(
        (state) => state.resultData?.computeStatisticsPreviews,
        useLazyGetComputeStatisticsPreviewsQuery,
        (analysisId, computeStatisticsPreviews) =>
            receivedComputeStatisticsPreviews({ analysisId, computeStatisticsPreviews }),
    )

export const usePreviewGeneSetEnrichment = () =>
    useResultTemplate(
        (state) => state.resultData?.geneSetEnrichmentPreviews,
        useLazyGetGeneSetEnrichmentPreviewsQuery,
        (analysisId, geneSetEnrichmentPreviews) =>
            receivedGeneSetEnrichmentPreviews({ analysisId, geneSetEnrichmentPreviews }),
    )

export const usePreviewWGCNA = () =>
    useResultTemplate(
        (state) => state.resultData?.wgcnaPreviews,
        useLazyGetWGCNAPreviewsQuery,
        (analysisId, wgcnaPreviews) => receivedWGCNAPreviews({ analysisId, wgcnaPreviews }),
    )

export const useResultPathwayActivityInferenceCsvURL = () =>
    useResultTemplate(
        (state) => state.resultData?.pathwayActivityInferenceResultsCsvUrl,
        useLazyGetPathwayActivityInferenceCsvQuery,
        (analysisId, pathwayActivityInferenceResultsCsvUrl) =>
            receivedPathwayActivityInferenceResults({ analysisId, pathwayActivityInferenceResultsCsvUrl }),
    )

export const useResultDatasetGenesSet = () =>
    useResultModifiedTemplate(
        (state) => state.resultData?.datasetGenes,
        useLazyGetDatasetGenesQuery,
        (analysisId, datasetGenes) => receivedDatasetGenes({ analysisId, datasetGenes }),
        (datasetGenes) => {
            if (datasetGenes) {
                return new Set(datasetGenes)
            }
            return new Set<string>()
        },
    )

const lruExcessColumnLimit = 2

interface UseResultObsOutput {
    obsDataFrame: DataFrame | null
    setObsDataFrame: React.Dispatch<React.SetStateAction<DataFrame | null>>
    fetchObsColumn: (column: string) => Promise<Series | null>
    isFetching: boolean
}

export const useResultObs = (columns: string[]): UseResultObsOutput => {
    const dispatch = useAppDispatch()
    const analysisId = useAppSelector((state) => state.analysisResultsHolder.analysisId)

    const [dataFrame, setDataFrame] = useState<DataFrame | null>(null)
    const reloadObs = useAppSelector((state) => state.analysisResultsHolder.reloadObs)

    const latestUsedColumns = useRef<LRUSet<string>>(new LRUSet(lruExcessColumnLimit))
    const fetchingColumns = useRef<Set<string>>(new Set())

    const [getColumnApi, { isFetching }] = useLazyGetObsColumnQuery()

    const fetchColumn = async (column: string) => {
        let data: (string | number | boolean)[] = []

        if (!analysisId) {
            return new Series(data)
        }

        try {
            // If we have column just return series.
            if (dataFrame && dataFrame.columns.includes(column)) {
                latestUsedColumns.current.touch(column)
                return dataFrame.column(column)
            }

            fetchingColumns.current.add(column)

            data = await getColumnApi({ analysisId, column }).unwrap()
            // Status code 204 No content returns null data.
            if (!data) {
                console.log('obs columns is missing', column)
                return null
            }

            // Raise LRU limit over strictly necessary columns.
            latestUsedColumns.current.setLimit(columns.length + lruExcessColumnLimit)
            // Touch every necessary column so they don't get marked for removal.
            columns.forEach((column) => latestUsedColumns.current.touch(column))

            setDataFrame((df) => {
                try {
                    if (!df) {
                        return new DataFrame({ [column]: data })
                    }
                    if (df.shape.length < 1 || df.shape[0] !== data.length) {
                        console.warn(
                            `Received data - ${data.length} has different shape than current data frame ${df.shape[0]}, overwriting dataframe.`,
                        )
                        return new DataFrame({ [column]: data })
                    }

                    const newDf = df.copy()
                    newDf.addColumn(column, data, { inplace: true })

                    const oldColumn = latestUsedColumns.current.put(column)
                    if (oldColumn) {
                        newDf.drop({ columns: [oldColumn], inplace: true })
                    }

                    return newDf
                } catch (err) {
                    console.error(err)
                }

                return df
            })

            fetchingColumns.current.delete(column)
        } catch (err) {
            console.error(err)
        }

        return new Series(data)
    }

    useEffect(() => {
        if (!analysisId) {
            return
        }

        columns
            .filter((column) => !dataFrame?.columns.includes(column))
            .filter((column) => !fetchingColumns.current.has(column))
            .forEach((column) => fetchColumn(column).catch(console.log))
    }, [columns, dataFrame, analysisId])

    useEffect(() => {
        if (!analysisId) {
            return
        }

        if (reloadObs) {
            setDataFrame(null)
            dispatch(receivedReloadObs({ analysisId, reload: false }))
        }
    }, [reloadObs, analysisId])

    return { obsDataFrame: dataFrame, setObsDataFrame: setDataFrame, fetchObsColumn: fetchColumn, isFetching }
}

export const selectAnalysisNormalized = (state: RootState) => state.analysisResultsHolder.resultData?.normalized
export const selectAnalysisUmapComputed = (state: RootState) => state.analysisResultsHolder.resultData?.umapComputed

export default analysisResultsSlice.reducer
