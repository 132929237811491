import { SlideUpTransition } from '../../../utils/transitions'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Typography } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { DialogProps, GeneCoPerturbationResultNotificationProps } from '../../../model/model'
import Dialog from '@mui/material/Dialog'
import { useLazyGetResultUrlQuery } from '../../common-api/asyncJobApiSlice'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import { DataFrame, readCSV } from 'danfojs'
import { receivedAppMessage } from '../../dashboard/appMessageSlice'
import { useAppDispatch } from '../../../app/hooks'
import GridToolbarWithSaveGenes from '../../workbench/analysis/common/GridToolbarWithSaveGenes'
import { SingleGeneExtractorFactory } from '../../../utils/grid'
import DataFrameGrid from '../../workbench/analysis/common/DataFrameGrid'
import CreateOrUpdateGeneListDialog from '../../genelist/CreateOrUpdateGeneListDialog'
import ResultsLoadingOrMissing from '../../workbench/analysis/common/ResultsLoadingOrMissing'
import useWindowSize from '../../../hooks/useWindowResize'

export interface GeneCoPerturbationDialogParams extends DialogProps {
    result: GeneCoPerturbationResultNotificationProps
}

export default function GeneCoPerturbationResultDialog({
    openDialog,
    handleCloseDialog,
    result,
}: GeneCoPerturbationDialogParams) {
    const dispatch = useAppDispatch()
    const [resultDataFrameLoading, setResultDataFrameLoading] = useState(false)
    const [resultDataFrame, setResultDataFrame] = useState<DataFrame | null>(null)
    const [openCreateGeneListDialog, setOpenCreateGeneListDialog] = useState(false)
    const [filteredGenes, setFilteredGenes] = useState<string[]>([])
    const { width = 0 } = useWindowSize()
    const [getResultUrl, { isFetching }] = useLazyGetResultUrlQuery()

    const close = useDeliberateDialogClose(() => {
        handleCloseDialog()
    })

    useEffect(() => {
        console.log(`CHANGED ${openDialog}`)
    }, [openDialog])

    useEffect(() => {
        if (openDialog) {
            setResultDataFrameLoading(true)
            getResultUrl(result.coPerturbedGenesCsv)
                .unwrap()
                .then((resp) => {
                    readCSV(resp.url, { skipEmptyLines: 'greedy' })
                        .then((df) => {
                            setResultDataFrame(df)
                        })
                        .catch(() => {
                            dispatch(
                                receivedAppMessage({
                                    type: 'error',
                                    message: 'Error loading co-perturbed genes data.',
                                }),
                            )
                        })
                        .finally(() => {
                            setResultDataFrameLoading(true)
                        })
                })
                .finally(() => {
                    setResultDataFrameLoading(true)
                })
        }
    }, [openDialog, getResultUrl, result])

    return (
        <Dialog fullScreen open={openDialog} onClose={close} TransitionComponent={SlideUpTransition}>
            <AppBar sx={{ position: 'relative' }} color={'default'}>
                <Toolbar variant='dense'>
                    <IconButton edge='start' color='inherit' onClick={close} aria-label='close'>
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                        <Typography sx={{ ml: 2 }} variant='h6' component='div'>
                            Gene Co-Perturbation Report for <b>{result.targetGene}</b>
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box sx={{ display: 'flex', p: 2, width: '100%', flexDirection: 'column' }}>
                {resultDataFrame ? (
                    <>
                        <DataFrameGrid
                            df={resultDataFrame}
                            slots={{
                                toolbar: GridToolbarWithSaveGenes,
                            }}
                            slotProps={{
                                toolbar: {
                                    saveGenesCallback: (filteredGenes: string[]) => {
                                        setFilteredGenes(filteredGenes)
                                        setOpenCreateGeneListDialog(true)
                                    },
                                    extractGenesCallback: SingleGeneExtractorFactory('gene'),
                                },
                            }}
                            width={Math.max(width - 50, 550)}
                            height={window.innerHeight - 100}
                            showBorder
                        />
                        <CreateOrUpdateGeneListDialog
                            openDialog={openCreateGeneListDialog}
                            handleCloseDialog={() => {
                                setOpenCreateGeneListDialog(false)
                            }}
                            externalGenes={filteredGenes}
                        />
                    </>
                ) : (
                    <ResultsLoadingOrMissing
                        loading={isFetching || resultDataFrameLoading}
                        loadingMessage={'Loading data table...'}
                        missingMessage={'There are no differentially expressed genes between your selected groups.'}
                    />
                )}
            </Box>
        </Dialog>
    )
}
