import Dialog from '@mui/material/Dialog'
import { useGetCoPerturbedGenesMutation } from '../../common-api/asyncJobApiSlice'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import { DialogProps } from '../../../model/model'
import React, { ChangeEvent, useState } from 'react'
import { DEFAULT_MIN_ABS_LOG_FOLD_CHANGE, DEFAULT_PVALUE_CUTOFF } from '../../../utils/constants'
import { Button, CircularProgress, FormControlLabel, Stack, Switch, TextField, Typography } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import { useTranslation } from 'react-i18next'

interface GeneCoPerturbationFormDialogParams extends DialogProps {
    geneSignatureIds: number[]
}

export default function GeneCoPerturbationFormDialog({
    openDialog,
    handleCloseDialog,
    geneSignatureIds,
}: GeneCoPerturbationFormDialogParams) {
    const { t } = useTranslation()
    const [targetGene, setTargetGene] = useState('')
    const [targetGeneError, setTargetGeneError] = useState(false)
    const [pValueCutoff, setPValueCutoff] = useState<number>(DEFAULT_PVALUE_CUTOFF)
    const [pValueCutoffError, setPValueCutoffError] = useState(false)
    const [minAbsLogFC, setMinAbsLogFC] = useState<number>(DEFAULT_MIN_ABS_LOG_FOLD_CHANGE)
    const [minAbsLogFCError, setMinAbsLogFCError] = useState(false)
    const [raw, setRaw] = useState(false)

    const [getCoPerturbedGenesApi, { isLoading }] = useGetCoPerturbedGenesMutation()

    const handleTargetGeneChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setTargetGene(event.target.value)
    }

    const handlePValueCutoffChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPValueCutoff(parseFloat(event.target.value))
    }

    const handleMinAbsLogFCChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setMinAbsLogFC(parseFloat(event.target.value))
    }

    const handleRawChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setRaw(checked)
    }

    const resetErrors = () => {
        setTargetGeneError(false)
        setPValueCutoffError(false)
        setMinAbsLogFCError(false)
    }
    const resetForm = () => {
        resetErrors()
        setTargetGene('')
        setPValueCutoff(DEFAULT_PVALUE_CUTOFF)
        setMinAbsLogFC(DEFAULT_MIN_ABS_LOG_FOLD_CHANGE)
        setRaw(false)
    }

    const validateForm = () => {
        resetErrors()
        let valid = true
        if (!targetGene) {
            setTargetGeneError(true)
            valid = false
        }
        if (pValueCutoff == null || isNaN(pValueCutoff) || pValueCutoff < 0) {
            setPValueCutoffError(true)
            valid = false
        }
        if (minAbsLogFC == null || isNaN(minAbsLogFC) || minAbsLogFC < 0) {
            setMinAbsLogFCError(true)
            valid = false
        }
        return valid
    }

    const submit = () => {
        if (!validateForm()) {
            return
        }
        getCoPerturbedGenesApi({
            geneSignatureIds: geneSignatureIds,
            targetGene: targetGene,
            pValThreshold: pValueCutoff,
            minAbsLogFoldChange: minAbsLogFC,
            raw: raw,
        }).then(() => {
            close()
        })
    }

    useSubmitOnEnter(submit, openDialog)

    const close = useDeliberateDialogClose(() => {
        resetForm()
        handleCloseDialog()
    })

    return (
        <Dialog open={openDialog} onClose={close} maxWidth={'md'}>
            <DialogTitle>Determine Co-Perturbed Genes</DialogTitle>
            <DialogContent sx={{ width: '512px' }}>
                <Stack>
                    <TextField
                        placeholder={'The target gene for co-perturbation reporting'}
                        sx={{ mt: 2, mb: 2 }}
                        value={targetGene}
                        onChange={handleTargetGeneChange}
                        error={targetGeneError}
                        fullWidth
                    />
                    <TextField
                        type='number'
                        label='p-value cutoff'
                        required
                        error={pValueCutoffError}
                        value={pValueCutoff}
                        onChange={handlePValueCutoffChange}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        type='number'
                        label='Min abs log fold change'
                        required
                        error={minAbsLogFCError}
                        value={minAbsLogFC}
                        onChange={handleMinAbsLogFCChange}
                        sx={{ mb: 2 }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={raw}
                                onChange={handleRawChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={<Typography>Use unadjusted p-value for thresholding</Typography>}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color={'error'}>
                    {t('cancel')}
                </Button>
                <Button onClick={submit}>{isLoading ? <CircularProgress size={20} /> : <>Submit</>}</Button>
            </DialogActions>
        </Dialog>
    )
}
