import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../app/hooks'
import { useDeliberateDialogClose } from '../../hooks/useDeliberateDialogClose'
import { useSubmitOnEnter } from '../../hooks/useSubmitOnEnter'
import { DialogProps } from '../../model/model'
import { useTerminateRuntimeMutation } from './runtimeApiSlice'
import { setRuntime } from './runtimeSlice'

export default function TerminateRuntimeDialog(props: DialogProps) {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [terminateRuntime, { isLoading: isTerminating }] = useTerminateRuntimeMutation()

    const submit = () => {
        terminateRuntime()
            .unwrap()
            .then(() => {
                dispatch(
                    setRuntime({
                        exists: false,
                        proc: null,
                        instanceType: null,
                        createdAt: null,
                        connected: false,
                        maxIdleTimeHours: null,
                    }),
                )
                props.handleCloseDialog()
            })
    }

    useSubmitOnEnter(submit, props.openDialog)

    const close = useDeliberateDialogClose(props.handleCloseDialog)

    return (
        <Dialog
            open={props.openDialog}
            onClose={close}
            aria-labelledby='terminate-runtime-alert-dialog-title'
            aria-describedby='terminate-runtime-alert-dialog-description'
        >
            <DialogTitle id='terminate-runtime-alert-dialog-title'>{t('terminateRuntimeConfirmTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText id='terminate-runtime-alert-dialog-description'>
                    {t('terminateRuntimeConfirmContent')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('close')}
                </Button>
                {!isTerminating ? (
                    <Button onClick={submit} autoFocus>
                        {t('terminate')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
