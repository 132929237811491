import { Box } from '@mui/material'
import {
    DataGridPremium,
    getGridBooleanOperators,
    getGridNumericOperators,
    getGridStringOperators,
    GridColDef,
    GridFilterOperator,
    GridRenderCellParams,
    GridRowSelectionModel,
} from '@mui/x-data-grid-premium'
import { GridPremiumSlotsComponent } from '@mui/x-data-grid-premium/models/gridPremiumSlotsComponent'
import { GridSlotsComponentsProps } from '@mui/x-data-grid/models/gridSlotsComponentsProps'
import { DataFrame } from 'danfojs'
import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type Render = (params: GridRenderCellParams) => React.ReactNode

interface DataFrameGridProps {
    df: DataFrame
    slots?: Partial<GridPremiumSlotsComponent>
    slotProps?: GridSlotsComponentsProps
    width: number
    height: number
    showBorder?: boolean
    minColWidth?: number
    decimals?: number
    columnCustomFilterOperators?: Record<string, GridFilterOperator[]>
    customRenderer?: (field: string) => Render | undefined
    checkboxSelection?: boolean
}

const DataFrameGrid = ({
    df,
    slots,
    slotProps,
    width,
    height,
    showBorder,
    minColWidth,
    decimals,
    columnCustomFilterOperators,
    customRenderer,
    checkboxSelection,
}: DataFrameGridProps) => {
    const { t } = useTranslation()
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])

    const rows = useMemo(() => {
        return df.values.map((r, idx) => {
            const rowData: { [p: string]: string | number | boolean } = { id: idx }
            if (Array.isArray(r)) {
                for (let i = 0; i < r.length; i++) {
                    rowData[df.columns[i]] = r[i]
                }
            }
            return rowData
        })
    }, [df])



    const columns = useMemo(() => {
        return df.columns.map((c) => {
            const [colType, filterOperators, initialRenderCell] = determineColumnDefinitions(c, df, decimals)
            let renderCell = initialRenderCell

            if (columnCustomFilterOperators) {
                const extraOperators = columnCustomFilterOperators[c]
                if (extraOperators) {
                    filterOperators.push(...extraOperators)
                }
            }

            const customRender = customRenderer ? customRenderer(c) : undefined
            if (customRender) {
                renderCell = customRender
            }

            return {
                type: colType,
                field: c,
                headerName: t(c),
                groupable: false,
                filterable: true,
                filterOperators: filterOperators,
                sortable: true,
                width: minColWidth
                    ? Math.max(Math.floor(width / df.columns.length), minColWidth)
                    : Math.floor(width / df.columns.length),
                renderCell: renderCell,
            } as GridColDef
        })
    }, [df, width, minColWidth])

    return (
        <Box sx={{ height: `${height}px`, width: `${width}px` }}>
            <DataGridPremium
                sx={{
                    border: showBorder ? '1px solid #ddd' : 0,
                    '& .MuiDataGrid-toolbarContainer': {
                        pt: 1,
                        pb: 1,
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderRadius: 0,
                        minHeight: '40px!important',
                        maxHeight: '40px!important',
                    },
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#EEE',
                        height: '40px!important',
                    },
                    '& .MuiTablePagination-toolbar, & .MuiDataGrid-footerContainer': {
                        minHeight: '40px!important',
                    },
                }}
                rows={rows}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel)
                }}
                columns={columns}
                disableColumnSelector
                disableAggregation
                rowHeight={30}
                pagination={true}
                disableMultipleColumnsSorting
                slots={slots}
                slotProps={slotProps}
                checkboxSelection={checkboxSelection}
                keepNonExistentRowsSelected={true}
            />
        </Box>
    )
}

export const formatNumber = (decimals?: number) => {
    return (params: GridRenderCellParams) => {
        if (params.value) {
            if (Math.round(params.value) == params.value) {
                return params.value
            }
            if ((params.value < 1e-10 && params.value >= 0) || (params.value > -1e-10 && params.value < 0)) {
                return params.value.toExponential(decimals ? decimals : 6)
            }
            if (typeof params.value === 'number') {
                return params.value.toFixed(decimals ? decimals : 10)
            } else {
                return params.value
            }
        }
        return params.value
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const determineColumnDefinitions = (c: string, df: DataFrame | undefined | null, numberDecimals?: number): [string, any, (params: GridRenderCellParams) => unknown] => {
    let colType
    let filterOperators
    let renderCell = (params: GridRenderCellParams) => {
        return params.value
    }

    switch (df?.ctypes.at(c)) {
        case 'int32':
        case 'float32':
            colType = 'number'
            filterOperators = getGridNumericOperators()
            renderCell = formatNumber(numberDecimals)
            break
        case 'boolean':
            colType = 'boolean'
            filterOperators = getGridBooleanOperators()
            break
        default:
            colType = 'string'
            filterOperators = getGridStringOperators()
    }

    return [colType, filterOperators, renderCell]
}

export default memo(DataFrameGrid)
