export const getBaseHttpURL = (): string => {
    switch (import.meta.env.VITE_PLATFORM) {
        case 'dnanexus':
            return window.location.protocol + '//' + window.location.host
        case 'aws':
            return import.meta.env.VITE_SERVER_HTTP_ENDPOINT
        default:
            //TODO: send error
            return ''
    }
}

export const getBaseWebsocketURL = (): string => {
    switch (import.meta.env.VITE_PLATFORM) {
        case 'dnanexus':
            return 'wss://' + window.location.host
        case 'aws':
            return import.meta.env.VITE_SERVER_WS_ENDPOINT
        default:
            //TODO: send error
            return ''
    }
}
