import { GridRowId } from '@mui/x-data-grid'
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import '../../extensions/string.ext'
import { Metadata, MetadataFieldType, Sample, SampleStatus } from '../../model/model'

type MutationStorage = {
    [Key: number]: SampleRow
}

type sampleRowBase = {
    id: number
    name: string
    type: string
    status: string
    error: string
    batchId: string
    patient_externalId: string
    normalized: boolean
    createdAt: string
    createdBy: string
    dirty: boolean
    inUse?: boolean
}

export type SampleRow = sampleRowBase & Record<string | symbol, Metadata | string>

const samples = createEntityAdapter<Sample, number>({
    selectId: (x) => x.id,
    sortComparer: (a, b) => (b.createdAt.toNumber() > a.createdAt.toNumber() ? 1 : -1),
})

const rows = createEntityAdapter<SampleRow, number>({
    selectId: (x) => x.id,
})

type SampleListState = {
    samples: EntityState<Sample, number>
    rows: EntityState<SampleRow, number>
    totalCount: number
    processingMetadataFile: boolean
}

const mutationStorageKey = 'sample-mutations'

const initialState = {
    samples: samples.getInitialState(),
    rows: rows.getInitialState(),
    totalCount: 0,
    processingMetadataFile: false,
} as SampleListState

const sampleListSlice = createSlice({
    name: 'sampleListHolder',
    initialState: initialState,
    reducers: {
        receivedSamplesList: (
            state,
            { payload: { sampleList, totalCount } }: PayloadAction<{ sampleList: Sample[]; totalCount: number }>,
        ) => {
            const mutationsRaw = localStorage.getItem(mutationStorageKey)
            const mutations = JSON.parse(mutationsRaw ?? '{}') as MutationStorage

            const sampleRows = sampleList.map((s) => mapSampleToRow(s, mutations))

            samples.setAll(state.samples, sampleList)
            rows.setAll(state.rows, sampleRows)
            state.totalCount = totalCount
        },
        receivedSamplesUpdate: (state, { payload: { sampleList } }: PayloadAction<{ sampleList: Sample[] }>) => {
            const mutationsRaw = localStorage.getItem(mutationStorageKey)
            const mutations = JSON.parse(mutationsRaw ?? '{}') as MutationStorage

            const sampleRows = sampleList.map((s) => mapSampleToRow(s, mutations))

            samples.upsertMany(state.samples, sampleList)
            rows.setMany(state.rows, sampleRows)
        },
        receivedSamplesStatusUpdate: (
            state,
            {
                payload: { sampleIdList, status },
            }: PayloadAction<{
                sampleIdList: number[]
                status: SampleStatus
            }>,
        ) => {
            samples.updateMany(
                state.samples,
                sampleIdList.map((id) => {
                    return {
                        id: id,
                        changes: {
                            status: status,
                        },
                    }
                }),
            )
        },
        rowsUpdated: (state, { payload: { updatedRows } }: PayloadAction<{ updatedRows: SampleRow[] }>) => {
            const mutationsRaw = localStorage.getItem(mutationStorageKey)
            const mutations = JSON.parse(mutationsRaw ?? '{}') as MutationStorage

            for (const row of updatedRows) {
                mutations[row.id] = row

                const sample = state.samples.entities[row.id]
                if (sample) {
                    const sampleRow = mapSampleToRow(sample, mutations)
                    rows.setOne(state.rows, sampleRow)
                }
            }

            localStorage.setItem(mutationStorageKey, JSON.stringify(mutations))
        },
        revertSelectedChanges: (state, { payload: { selection } }: PayloadAction<{ selection: GridRowId[] }>) => {
            const mutationsRaw = localStorage.getItem(mutationStorageKey)
            const mutations = JSON.parse(mutationsRaw ?? '{}') as MutationStorage

            for (const rowId of selection) {
                if (typeof rowId == 'number') {
                    delete mutations[rowId]

                    const sample = state.samples.entities[rowId]
                    if (sample) {
                        const sampleRow = mapSampleToRow(sample, mutations)
                        rows.setOne(state.rows, sampleRow)
                    }
                }
            }
            localStorage.setItem(mutationStorageKey, JSON.stringify(mutations))
        },
        setProcessingMetadataFile: (state, { payload: { processing } }: PayloadAction<{ processing: boolean }>) => {
            state.processingMetadataFile = processing
        },
        receivedDeletedSampleIds: (state, { payload: { sampleIdList } }: PayloadAction<{ sampleIdList: number[] }>) => {
            samples.removeMany(state.samples, sampleIdList)
            rows.removeMany(state.rows, sampleIdList)
        },
    },
})

export const {
    receivedSamplesList,
    receivedSamplesUpdate,
    receivedSamplesStatusUpdate,
    rowsUpdated,
    revertSelectedChanges,
    setProcessingMetadataFile,
    receivedDeletedSampleIds,
} = sampleListSlice.actions

const mapSampleToRow = (sample: Sample, mutations: MutationStorage): SampleRow => {
    const dirtySample = mutations[sample.id]
    if (dirtySample) {
        return {
            ...dirtySample,
            dirty: true,
        } as SampleRow
    }

    return {
        id: sample.id,
        name: sample.name,
        type: sample.type,
        status: sample.status,
        error: sample.error,
        batchId: sample.batchId,
        patient_externalId: sample.patient?.externalId,
        normalized: sample.normalized,
        createdAt: sample.createdAt.parseAndFormatDate(),
        createdBy: `${sample.user.firstName} ${sample.user.lastName}`,
        dirty: false,
        inUse: sample.inUse,
        ...expandMetadata(sample.metadata ?? []),
    } as SampleRow
}

const expandMetadata = (metadata: Metadata[]) => {
    const record: Record<string | symbol, Metadata | string> = {}
    for (const m of metadata) {
        record[m.field.name] = m.field.type == MetadataFieldType.Ontology ? m : m.value
    }

    return record
}

export const selectTotalCount = (state: RootState) => state.sampleListHolder.totalCount

export const selectProcessingMetadataFile = (state: RootState) => state.sampleListHolder.processingMetadataFile

export const {
    selectAll: selectAllSamples,
    selectById: selectSampleById,
    selectIds: selectSampleIds,
    selectTotal: selectTotalSamples,
    selectEntities: selectSampleEntities,
} = samples.getSelectors<RootState>((state) => state.sampleListHolder.samples)

export const { selectAll: selectAllSampleRows } = rows.getSelectors<RootState>((state) => state.sampleListHolder.rows)

export default sampleListSlice.reducer
