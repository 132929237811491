import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import DataUsageIcon from '@mui/icons-material/DataUsage'
import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import { Box, CircularProgress, List, ListItem, Popover } from '@mui/material'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { bindPopover, bindTrigger } from 'material-ui-popup-state'
import { usePopupState } from 'material-ui-popup-state/hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { useUserPermitted } from '../auth/authSlice'
import CreateRuntimeDialog from './CreateRuntimeDialog'
import RuntimeDetailsDialog from './RuntimeDetailsDialog'
import TerminateRuntimeDialog from './TerminateRuntimeDialog'
import { useLazyGetActiveRuntimeInfoQuery } from './runtimeApiSlice'
import { selectRuntime, setRuntime } from './runtimeSlice'

export default function RuntimeControl() {
    const canUserRunAnalysis = useUserPermitted({ runAnalyses: true })

    return canUserRunAnalysis && <InnerRuntimeControl />
}

function InnerRuntimeControl() {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const runtimeDetails = useAppSelector(selectRuntime)
    const [openTerminateDialog, setOpenTerminateDialog] = useState(false)
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false)
    const [openCreateDialog, setOpenCreateDialog] = useState(false)
    const [getActiveRuntimeApi, { isFetching }] = useLazyGetActiveRuntimeInfoQuery()

    const handleCloseTerminateDialog = () => {
        setOpenTerminateDialog(false)
    }

    const handleCloseDetailsDialog = () => {
        setOpenDetailsDialog(false)
    }

    const handleCloseCreateDialog = () => {
        setOpenCreateDialog(false)
    }

    const updateRuntimeInfo = async () => {
        const runtimeResponse = await getActiveRuntimeApi().unwrap()

        dispatch(
            setRuntime({
                exists: runtimeResponse.exists,
                proc: runtimeResponse.proc,
                instanceType: runtimeResponse.instanceType,
                createdAt: runtimeResponse.createdAt ? runtimeResponse.createdAt.parseAndFormatDate() : '',
                connected: runtimeResponse.online,
                maxIdleTimeHours: runtimeResponse.maxIdleTimeHours,
            }),
        )
    }

    useEffect(() => {
        // Only need the fetch for first load, avoid reusing stale data.
        if (!runtimeDetails.loaded) {
            updateRuntimeInfo()
        }
    }, [runtimeDetails])

    const popupState = usePopupState({ variant: 'popover', popupId: 'runtime-control-menu' })

    return (
        <Box>
            <Paper
                sx={{
                    display: 'flex',
                    height: '32px',
                    alignItems: 'center',
                    p: '0 2px',
                }}
            >
                {!isFetching ? (
                    <>
                        {runtimeDetails.exists ? (
                            <>
                                {runtimeDetails.connected ? (
                                    <LinkIcon sx={{ m: '8px' }} color='primary' />
                                ) : (
                                    <LinkOffIcon sx={{ m: '8px' }} color='error' />
                                )}
                                <Divider sx={{ height: 28 }} orientation='vertical' />
                                <Typography
                                    sx={{
                                        ml: 2,
                                        mr: 2,
                                        fontSize: '0.9em',
                                    }}
                                >
                                    {runtimeDetails.instanceType}
                                </Typography>
                                <TerminateRuntimeDialog
                                    openDialog={openTerminateDialog}
                                    handleCloseDialog={handleCloseTerminateDialog}
                                />
                                <RuntimeDetailsDialog
                                    openDialog={openDetailsDialog}
                                    handleCloseDialog={handleCloseDetailsDialog}
                                />
                            </>
                        ) : (
                            <>
                                <LinkOffIcon sx={{ m: '8px' }} color='error' />
                                <Divider sx={{ height: 28 }} orientation='vertical' />
                                <Typography sx={{ ml: 2, mr: 2, fontSize: '0.9em' }}>no runtime</Typography>
                                <CreateRuntimeDialog
                                    openDialog={openCreateDialog}
                                    handleCloseDialog={handleCloseCreateDialog}
                                />
                            </>
                        )}
                        <Divider sx={{ height: 28 }} orientation='vertical' />
                        <IconButton sx={{ p: '5px' }} aria-label='directions' {...bindTrigger(popupState)}>
                            <ArrowDropDownIcon />
                        </IconButton>
                    </>
                ) : (
                    <Box sx={{ width: '174px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress size={20} />
                    </Box>
                )}
            </Paper>
            <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {runtimeDetails.exists ? (
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    setOpenDetailsDialog(true)
                                    popupState.close()
                                }}
                            >
                                <ListItemIcon>
                                    <DataUsageIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('runtimeDetails')} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    setOpenTerminateDialog(true)
                                    popupState.close()
                                }}
                            >
                                <ListItemIcon>
                                    <LinkOffIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('terminateRuntime')} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                ) : (
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => {
                                    setOpenCreateDialog(true)
                                    popupState.close()
                                }}
                            >
                                <ListItemIcon>
                                    <LinkIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('createRuntime')} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                )}
            </Popover>
        </Box>
    )
}
