import { apiSlice } from '../../app/apiSlice'
import { LoginResponse } from '../auth/authApiSlice'

export type UpdateUserPropRequest = {
    firstName: string | undefined
    lastName: string | undefined
    receiveMarketing: boolean | undefined
    receiveJobNotification: string | undefined
}

type UpdateEmailRequest = {
    newEmail: string
    password: string
}

type UpdatePhoneRequest = {
    newPhone: string
    password: string
}

type ChangePasswordRequest = {
    currentPassword: string
    newPassword: string
}

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        updateUserProp: builder.mutation<void, UpdateUserPropRequest>({
            query: (req) => ({
                url: '/private/user/update-prop',
                method: 'POST',
                body: req,
            }),
        }),
        updateEmail: builder.mutation<void, UpdateEmailRequest>({
            query: (req) => ({
                url: '/private/user/update-email',
                method: 'POST',
                body: req,
            }),
        }),
        updatePhone: builder.mutation<void, UpdatePhoneRequest>({
            query: (req) => ({
                url: '/private/user/update-phone',
                method: 'POST',
                body: req,
            }),
        }),
        changePassword: builder.mutation<void, ChangePasswordRequest>({
            query: (req) => ({
                url: '/private/user/change-password',
                method: 'POST',
                body: req,
            }),
        }),
        acceptEULA: builder.mutation<void, void>({
            query: () => ({
                url: 'private/user/acceptEULA',
                method: 'POST',
                body: {},
            }),
        }),
        getUserWithLicense: builder.query<LoginResponse, string>({
            query: (token) => ({
                url: '/private/user/get',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
        }),
    }),
})

export const {
    useUpdateUserPropMutation,
    useUpdateEmailMutation,
    useUpdatePhoneMutation,
    useChangePasswordMutation,
    useAcceptEULAMutation,
    useLazyGetUserWithLicenseQuery,
    endpoints: { updateUserProp, updateEmail, updatePhone, changePassword, acceptEULA, getUserWithLicense },
} = userApiSlice
