import { Box, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { GridRowId } from '@mui/x-data-grid'
import MuiPhoneNumber from 'material-ui-phone-number-2'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import { User } from '../../../model/model'
import { idCast } from '../../../utils/misc'
import { useGetRoleListQuery } from '../role/roleApiSlice'
import { useCreateOrUpdateUserMutation } from './adminUserApiSlice'
import { receivedNewOrUpdatedUser, selectUserById } from './adminUserSlice'

export default function CreateOrUpdateUserDialog(props: {
    openDialog: boolean
    handleCloseDialog: () => void
    userId?: GridRowId
}) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [firstName, setFirstName] = useState('')
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastName, setLastName] = useState('')
    const [lastNameError, setLastNameError] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [phone, setPhone] = useState('')
    const [role, setRole] = useState<number | null>(null)
    const [roleError, setRoleError] = useState(false)

    const user = useAppSelector<User | undefined>((state) => {
        if (props.userId) {
            return selectUserById(state, idCast(props.userId))
        }
        return undefined
    })
    const { data: response } = useGetRoleListQuery()
    const roleList = response?.roleList
    const [createOrUpdate, { isLoading: isLoading }] = useCreateOrUpdateUserMutation()

    useEffect(() => {
        if (props.openDialog) {
            resetFormValues()
        }
        if (!roleList) {
            return
        }
        if (!user) {
            return
        }
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setEmail(user.email)
        setPhone(user.phone)
        setRole(user.role?.id)
    }, [user, roleList, props.openDialog])

    const validateForm = () => {
        resetErrors()
        let valid = true
        if (!firstName) {
            setFirstNameError(true)
            valid = false
        }
        if (!lastName) {
            setLastNameError(true)
            valid = false
        }
        if (!email) {
            setEmailError(true)
            valid = false
        }
        if (!role) {
            setRoleError(true)
            valid = false
        }
        return valid
    }

    const resetErrors = () => {
        setFirstNameError(false)
        setLastNameError(false)
        setEmailError(false)
        setRoleError(false)
    }

    const resetFormValues = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setRole(null)
    }

    const submit = async () => {
        if (!validateForm()) {
            return
        }
        const resp = await createOrUpdate({
            id: props.userId as number,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            roleId: role!,
        }).unwrap()

        dispatch(
            receivedNewOrUpdatedUser({
                user: resp.user,
            }),
        )
        close()
    }

    const close = useDeliberateDialogClose(() => {
        props.handleCloseDialog()
        resetErrors()
    })

    const handleFirstNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setLastName(event.target.value)
    }

    const handleEmailChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
        if (typeof event === 'string') {
            setPhone(event)
        } else {
            setPhone(event.target.value)
        }
    }

    const handleRoleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRole(parseInt((event.target as HTMLInputElement).value))
    }

    return (
        <Dialog open={props.openDialog} onClose={close} maxWidth={'sm'}>
            <DialogTitle>{props.userId ? t('editUser') : t('createUser')}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '400px' }}>
                    <Box
                        sx={{
                            width: '100%',
                            '& .MuiTextField-root': {
                                mt: 2,
                            },
                        }}
                    >
                        <TextField
                            onChange={handleFirstNameChange}
                            value={firstName}
                            label={t('firstName')}
                            error={firstNameError}
                            inputProps={{
                                autoComplete: 'off',
                                form: {
                                    autoComplete: 'off',
                                },
                            }}
                            required
                            fullWidth
                        />
                        <TextField
                            onChange={handleLastNameChange}
                            value={lastName}
                            label={t('lastName')}
                            error={lastNameError}
                            inputProps={{
                                autoComplete: 'off',
                                form: {
                                    autoComplete: 'off',
                                },
                            }}
                            required
                            fullWidth
                        />
                        <TextField
                            onChange={handleEmailChange}
                            value={email}
                            label={t('email')}
                            error={emailError}
                            inputProps={{
                                autoComplete: 'off',
                                form: {
                                    autoComplete: 'off',
                                },
                            }}
                            required
                            fullWidth
                        />
                        <MuiPhoneNumber
                            defaultCountry={'us'}
                            onlyCountries={['us', 'gb', 'au', 'ca', 'ro']}
                            disableAreaCodes
                            onChange={handlePhoneChange}
                            value={phone}
                            variant='outlined'
                            fullWidth
                        />
                        <Typography variant='h6' color={roleError ? 'error' : undefined} sx={{ mt: 2 }}>
                            User Role*
                        </Typography>
                        <Box>
                            <FormControl>
                                <RadioGroup value={role} onChange={handleRoleRadioChange}>
                                    {roleList?.map((r, idx) => {
                                        return (
                                            <Box key={`role-${idx}`}>
                                                <FormControlLabel
                                                    sx={{
                                                        '& .MuiTypography-root': {
                                                            fontSize: '0.9em',
                                                        },
                                                    }}
                                                    control={<Radio value={r.id} size='small' />}
                                                    label={r.name}
                                                />
                                            </Box>
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                {!isLoading ? (
                    <Button onClick={submit} autoFocus>
                        {t('submit')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
