import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import EditIcon from '@mui/icons-material/Edit'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import { Button, Divider, Tooltip } from '@mui/material'
import {
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    ToolbarPropsOverrides,
} from '@mui/x-data-grid-premium'
import { useTranslation } from 'react-i18next'

function CreateButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip
            title={
                disabled
                    ? 'You filled all the seats in your license. Contact support to add more seats.'
                    : 'Create a new user.'
            }
        >
            <span>
                <Button startIcon={<AddCircleOutlineIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('create')}
                </Button>
            </span>
        </Tooltip>
    )
}

function EditButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Edit the selected user.'}>
            <span>
                <Button startIcon={<EditIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('edit')}
                </Button>
            </span>
        </Tooltip>
    )
}

function ActivateButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Activate the selected users. Enabled only for inactive users.'}>
            <span>
                <Button startIcon={<HowToRegIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('activate')}
                </Button>
            </span>
        </Tooltip>
    )
}

function InactivateButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Inactivate the selected users. Enabled only for active users.'}>
            <span>
                <Button
                    startIcon={<PersonOffIcon />}
                    onClick={onClick}
                    size={'small'}
                    disabled={disabled}
                    color={'error'}
                >
                    {t('inactivate')}
                </Button>
            </span>
        </Tooltip>
    )
}

declare module '@mui/x-data-grid-premium' {
    interface ToolbarPropsOverrides {
        createCallback: () => void
        editCallback: () => void
        activateCallback: () => void
        inactivateCallback: () => void
        disableCreate: boolean
        disableEdit: boolean
        disableActivate: boolean
        disableInactivate: boolean
    }
}

function UserGridToolbar({
    createCallback,
    editCallback,
    activateCallback,
    inactivateCallback,
    disableCreate,
    disableEdit,
    disableActivate,
    disableInactivate,
}: ToolbarPropsOverrides) {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton slotProps={{ tooltip: { arrow: true } }} />
            <GridToolbarExport />
            <Divider orientation='vertical' variant='middle' flexItem />
            <CreateButton onClick={createCallback} disabled={disableCreate} />
            <EditButton onClick={editCallback} disabled={disableEdit} />
            <ActivateButton onClick={activateCallback} disabled={disableActivate} />
            <InactivateButton onClick={inactivateCallback} disabled={disableInactivate} />
        </GridToolbarContainer>
    )
}

export default UserGridToolbar
