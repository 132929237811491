import {
    Autocomplete,
    Box,
    CircularProgress,
    FormControlLabel,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import '../../../extensions/number.ext'
import { useDeliberateDialogClose } from '../../../hooks/useDeliberateDialogClose'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { DialogProps, MetadataFieldType, Ontology } from '../../../model/model'
import {
    CreateMetadataFieldRequest,
    useCreateMetadataFieldMutation,
    useUpdateMetadataFieldMutation,
} from '../../common-api/metadataFieldApiSlice'
import { useGetOntologyListQuery } from '../../common-api/ontologyApiSlice'
import { receivedAppMessage } from '../../dashboard/appMessageSlice'
import {
    receivedNewMetadataField,
    receivedUpdatedMetadataField,
    selectMetadataFieldById,
} from './adminMetadataFieldSlice'

export interface CreateOrUpdateMetadataFieldParams extends DialogProps {
    metadataFieldId?: number | null
}

export default function CreateOrUpdateMetadataFieldDialog({
    openDialog,
    handleCloseDialog,
    metadataFieldId,
}: CreateOrUpdateMetadataFieldParams) {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [type, setType] = useState<MetadataFieldType | null>(null)
    const [typeError, setTypeError] = useState(false)
    const [index, setIndex] = useState(0)
    const [indexError, setIndexError] = useState(false)
    const [ontology, setOntology] = useState<Ontology | null>(null)
    const [ontologyError, setOntologyError] = useState(false)
    const [options, setOptions] = useState<string[] | null>(null)
    const [optionsError, setOptionsError] = useState(false)
    const [isSampleId, setIsSampleId] = useState(false)
    const [isPatientId, setIsPatientId] = useState(false)
    const [visibleByDefault, setVisibleByDefault] = useState(false)
    const [description, setDescription] = useState('')

    const { data: ontologyList } = useGetOntologyListQuery()

    const metadataField = useAppSelector((state) => selectMetadataFieldById(state, metadataFieldId ?? parseInt('')))
    const [createMetadataFieldApi, { isLoading: isCreating }] = useCreateMetadataFieldMutation()
    const [updateMetadataFieldApi, { isLoading: isUpdating }] = useUpdateMetadataFieldMutation()

    useEffect(() => {
        if (openDialog) {
            resetFormValues()
        }
        if (!metadataField) {
            return
        }
        setName(metadataField.name)
        setType(metadataField.type)
        setOntology(metadataField.ontology)
        setOptions(metadataField.options?.filter((o) => o != '') ?? null)
        setIndex(metadataField.index)
        setIsSampleId(metadataField.isSampleId)
        setIsPatientId(metadataField.isPatientId)
        setVisibleByDefault(metadataField.visibleByDefault)
        setDescription(metadataField.description)
    }, [metadataField, openDialog])

    useEffect(() => {
        if (type == MetadataFieldType.Ontology) {
            setOptions([])
        }
        if (type == MetadataFieldType.List) {
            setOntology(null)
        }
    }, [type])

    const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setName(event.target.value)
    }

    const handleIndexChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setIndex(parseInt(event.target.value))
    }

    const handleIsPatientIdChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsPatientId(checked)
    }

    const handleIsSampleIdChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsSampleId(checked)
    }

    const handleVisibleByDefaultChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setVisibleByDefault(checked)
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDescription(event.target.value)
    }

    const resetErrors = () => {
        setNameError(false)
        setIndexError(false)
        setTypeError(false)
        setOntologyError(false)
        setOptionsError(false)
    }

    const resetFormValues = () => {
        setName('')
        setIndex(0)
        setType(null)
        setOntology(null)
        setOptions(null)
        setIsPatientId(false)
        setIsSampleId(false)
        setVisibleByDefault(false)
        setDescription('')
    }

    const isValidName = (input: string): boolean => {
        // Regular expression to check the conditions:
        // ^          : start of the string
        // [a-z]      : the first character must be a lowercase letter
        // [a-z0-9._] : subsequent characters can be lowercase letters, digits, dot, or underscore
        // *          : zero or more occurrences of the allowed subsequent characters
        // $          : end of the string
        const regex = /^[a-z][a-z0-9._]*$/
        return regex.test(input)
    }

    const validateForm = () => {
        resetErrors()
        let valid = true
        if (!name || !isValidName(name)) {
            setNameError(true)
            valid = false
        }
        if (isNaN(index) || index < 0) {
            setIndexError(true)
            valid = false
        }
        if (!type) {
            setTypeError(true)
            valid = false
        }
        if (type == MetadataFieldType.Ontology && !ontology) {
            setOntologyError(true)
            valid = false
        }
        if (type == MetadataFieldType.List) {
            if (!options || options.length == 0) {
                setOptionsError(true)
                valid = false
            } else if (metadataField && metadataField.options && metadataField.inUse) {
                for (let i = 0; i < metadataField.options.length; i++) {
                    if (metadataField.options[i] && !options.includes(metadataField.options[i])) {
                        setOptionsError(true)
                        valid = false
                        break
                    }
                }
            }
        }
        return valid
    }

    const submit = async () => {
        if (!validateForm()) {
            return
        }
        if (metadataField && metadataFieldId && type) {
            const resp = await updateMetadataFieldApi({
                id: metadataFieldId,
                name: name,
                index: index,
                options: options != null ? ['', ...options] : options,
                ontologyId: ontology?.id,
                type: type,
                isPatientId: isPatientId,
                isSampleId: isSampleId,
                visibleByDefault: visibleByDefault,
                description: description,
            }).unwrap()
            dispatch(
                receivedUpdatedMetadataField({
                    metadataField: resp,
                }),
            )

            let msg = 'The metadata field was successfully updated.'
            if (metadataField.index != index) {
                msg += ' Refresh table for updated indexes.'
            }

            dispatch(
                receivedAppMessage({
                    type: 'success',
                    message: msg,
                }),
            )
        } else {
            if (!type) {
                return
            }
            const req = {
                name: name,
                index: index,
                type: type,
                ontologyId: ontology?.id,
                isPatientId: isPatientId,
                isSampleId: isSampleId,
                visibleByDefault: visibleByDefault,
                description: description,
            } as CreateMetadataFieldRequest
            if (options) {
                req.options = ['', ...options]
            }
            const newMetadataField = await createMetadataFieldApi(req).unwrap()
            dispatch(
                receivedNewMetadataField({
                    metadataField: newMetadataField,
                }),
            )
            dispatch(
                receivedAppMessage({
                    type: 'success',
                    message: 'The metadata field was successfully created.',
                }),
            )
        }

        close()
    }

    useSubmitOnEnter(submit, openDialog)

    const close = useDeliberateDialogClose(() => {
        handleCloseDialog()
        resetErrors()
    })

    return (
        <Dialog open={openDialog} onClose={close} maxWidth={'md'}>
            <DialogTitle>{!metadataFieldId ? 'Create a Metadata Field ' : 'Edit Metadata Field '}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '600px' }}>
                    <Box
                        sx={{
                            width: '100%',
                            '& .MuiTextField-root': {
                                mt: 2,
                            },
                        }}
                    >
                        <TextField
                            onChange={handleNameChange}
                            value={name}
                            label={t('name')}
                            error={nameError}
                            inputProps={{
                                autoComplete: 'off',
                                form: {
                                    autoComplete: 'off',
                                },
                            }}
                            required
                            fullWidth
                            disabled={metadataField?.inUse}
                            helperText={
                                'Lower case only, alphanumeric, dot or underscore, must start with letter. E.g.: sample.tissue, age_years.'
                            }
                        />
                        <Autocomplete
                            sx={{ width: '100%' }}
                            options={[
                                MetadataFieldType.String,
                                MetadataFieldType.Numeric,
                                MetadataFieldType.List,
                                MetadataFieldType.Ontology,
                            ]}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Type'
                                    error={typeError}
                                    required
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                            value={type}
                            onChange={(event, newValue: MetadataFieldType | null) => {
                                setType(newValue)
                            }}
                            disabled={metadataField?.inUse}
                        />
                        {type == MetadataFieldType.Ontology && (
                            <Autocomplete
                                sx={{ width: '100%' }}
                                options={ontologyList ?? []}
                                getOptionLabel={(o) => o.name}
                                isOptionEqualToValue={(o, v) => o.id == v.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Ontology'
                                        error={ontologyError}
                                        required
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                value={ontology}
                                onChange={(event, newValue: Ontology | null) => {
                                    setOntology(newValue)
                                }}
                                disabled={metadataField?.inUse}
                            />
                        )}
                        {type == MetadataFieldType.List && (
                            <Autocomplete
                                sx={{ width: '100%' }}
                                options={[]}
                                freeSolo
                                multiple
                                includeInputInList
                                value={options ?? []}
                                onChange={(_: unknown, newValue: string[]) => {
                                    setOptions(newValue)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('options')}
                                        helperText={
                                            metadataField?.inUse
                                                ? 'You cannot remove existing options.'
                                                : 'Type the option and hit enter to add it to the list.'
                                        }
                                        type='text'
                                        fullWidth
                                        required
                                        error={optionsError}
                                    />
                                )}
                            />
                        )}
                        <TextField
                            onChange={handleIndexChange}
                            value={index}
                            label={t('index')}
                            error={indexError}
                            inputProps={{
                                autoComplete: 'off',
                                form: {
                                    autoComplete: 'off',
                                },
                            }}
                            helperText={'Used for display purposes only in the sample list.'}
                            required
                            fullWidth
                            type={'number'}
                        />
                        <TextField
                            onChange={handleDescriptionChange}
                            value={description}
                            label={t('description')}
                            fullWidth
                            multiline
                            rows={3}
                        />
                        <Box sx={{ mt: 1 }}>
                            <Tooltip title={'Marks whether this field represents the patient/subject ID.'} arrow>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isPatientId}
                                            onChange={handleIsPatientIdChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={isSampleId}
                                        />
                                    }
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography>Is patient ID?</Typography>
                                            <Typography variant={'body2'} sx={{ fontStyle: 'italic', ml: 1 }}>
                                                Can only be applied to one metadata field.
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Tooltip>
                        </Box>
                        <Box sx={{ mt: 1 }}>
                            <Tooltip title={'Marks whether this field represents the sample ID.'} arrow>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isSampleId}
                                            onChange={handleIsSampleIdChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={isPatientId}
                                        />
                                    }
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography>Is sample ID?</Typography>
                                            <Typography variant={'body2'} sx={{ fontStyle: 'italic', ml: 1 }}>
                                                Can only be applied to one metadata field.
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Tooltip>
                        </Box>
                        <Box sx={{ mt: 1 }}>
                            <Tooltip
                                title={
                                    'Marks whether this field should be visible by default when displaying metadata.'
                                }
                                arrow
                            >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={visibleByDefault}
                                            onChange={handleVisibleByDefaultChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography>Visible By Default</Typography>
                                        </Box>
                                    }
                                />
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color={'error'}>
                    {t('cancel')}
                </Button>
                {!isCreating && !isUpdating ? (
                    <Button onClick={submit} autoFocus>
                        {t('submit')}
                    </Button>
                ) : (
                    <Button>
                        <CircularProgress size={20} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
